import React, { Component } from "react";
import { Image } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import Checkbox from "@material-ui/core/Checkbox";
import { NavLink } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { db } from "../../../util/firebase";
import {
  fetchData,
  savePlayer,
  downloadCV,
} from "../../../firebase/app/players";
import ProfileMenu from "./menu";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfDocument } from "./pdfDownload";
import VideoPlayer from "react-video-js-player";
import DotLoader from "react-spinners/DotLoader";
import dayjs from "dayjs";
import Graph from "./graph";

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000",
      light: "#2B2B2B",
      dark: "#FFFFFF",
    },
    secondary: {
      main: "#FFC65B",
    },
    grey: {
      main: "black",
    },
    action: {
      main: "black",
      light: "black",
      dark: "black",
    },
  },
});

class PlayerProfile extends Component {
  constructor(props) {
    super();
    this.state = {
      player: null,
      teams: [],
      linkId: localStorage.getItem("linkId"),
      data: null,
      saved: false,
      othersViewedPlayers: [],
      openSnackbar: false,
      openSnackbar2: false,
      showBadge: false,
      graphBase64: "",
    };

    this.onPlayerReady = this.onPlayerReady.bind(this);
  }

  async componentDidMount() {
    let teams = [];
    const playerDoc = await db
      .collection("players")
      .doc(this.props.match.params.player)
      .get();

    const teamsSnapshot = await db.collection("teams").get();
    teamsSnapshot.docs.forEach((doc) => {
      if (!doc.data().deleted) {
        teams.push({ id: doc.id, name: doc.data().name });
      }
    });

    // check player exist
    if (playerDoc.exists) {
      // get data from link
      await fetchData(this.state.linkId, (data) => {
        let saved = false;
        const randomFourPlayers = this.selectRandomPlayers([
          ...data.playersList,
        ]);
        const present = data.savedPlayers.some(
          (playerId) => playerId === playerDoc.id
        );
        if (present) {
          saved = true;
        }
        this.setState({
          player: {
            id: playerDoc.id,
            ...playerDoc.data(),
          },
          data: data,
          othersViewedPlayers: randomFourPlayers,
          saved: saved,
          teams: teams,
        });
      });
    }
  }

  handleClose = () => {
    this.setState({ openSnackbar: false, openSnackbar2: false });
  };

  handleSaveCurrentPlayer = () => {
    savePlayer(this.state.player, this.state.linkId, () => {
      if (this.state.saved) {
        this.setState({ openSnackbar2: true, showBadge: true });
      } else if (!this.state.saved) {
        this.setState({ openSnackbar: true, showBadge: true });
      }
      this.setState({ saved: !this.state.saved });
    });
  };

  handleSavePlayer = (player) => {
    const data = { ...this.state.data };
    const index = data.savedPlayers.findIndex(
      (playerId) => playerId === player.id
    );

    if (index !== -1) {
      // removing player from saved list
      data.savedPlayers.splice(index, 1);
      // if player is current player
      if (player.id === this.state.player.id) {
        this.setState({ data: data, openSnackbar2: true, saved: false });
      } else {
        this.setState({ data: data, openSnackbar2: true });
      }
    } else {
      // saving player
      data.savedPlayers.push(player.id);
      if (player.id === this.state.player.id) {
        this.setState({ data: data, openSnackbar: true, saved: true });
      } else {
        this.setState({ data: data, openSnackbar: true });
      }
    }
    savePlayer(player, this.state.linkId, () => {});
  };

  handleCVDownload = () => {
    downloadCV(this.state.player, this.state.linkId, () => {
      console.log("CV DOWNLOADED");
    });
  };

  selectRandomPlayers = (playersList) => {
    for (let i = playersList.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [playersList[i], playersList[j]] = [playersList[j], playersList[i]];
    }
    return playersList.slice(0, 4);
  };

  onPlayerReady = (player) => {
    player.volume(0);
    player.loop(true);
  };

  render() {
    const player = this.state.player;
    return player ? (
      <div className="public-app">
        <ThemeProvider theme={outerTheme}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={this.state.openSnackbar}
            onClose={this.handleClose}
            autoHideDuration={3000}
            message="Player Saved"
          />
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={this.state.openSnackbar2}
            onClose={this.handleClose}
            autoHideDuration={3000}
            message="Player Removed from Saved Players"
          />
          <div className="background">
            <div className="profile-menu">
              <ProfileMenu
                name={this.state.data ? this.state.data.sentTo : "name"}
                showBadge={this.state.showBadge}
              />
            </div>
            <div className="profile-video-div">
              {player.videoUrl ? (
                <VideoPlayer
                  controls
                  autoplay
                  loop
                  className="profile-video"
                  src={player.videoUrl}
                  bigPlayButton={false}
                  style={{ width: "100% !important", height: "80vh" }}
                  hideControls={[
                    "volume",
                    "timer",
                    "playbackrates",
                    "fullscreen",
                  ]}
                  onReady={this.onPlayerReady}
                />
              ) : null}

              {player.BGImageUrl && !player.videoUrl ? (
                <Image src={player.BGImageUrl} className="profile-video" />
              ) : null}
            </div>
          </div>

          <div className="container-fluid username-container">
            <div className="container profile-container">
              <div
                className="col-md-3 col-12 profile-sec"
                style={{ padding: 0 }}
              >
                <div className="align-items-center d-flex justify-content-around m-0 p row profileDiv">
                  <Image
                    src={
                      player.profileImage
                        ? player.profileImage
                        : require("./../../../assets/images/dp-placeholder.png")
                    }
                    className="user-pic"
                    style={{
                      width: "85px",
                      height: "80px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                  <div className="star-icon" title="Save Player">
                    <Checkbox
                      style={{ padding: "5px" }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      onClick={this.handleSaveCurrentPlayer}
                      icon={
                        <BookmarkBorderIcon
                          fontSize="small"
                          style={{ color: "#FFC65B" }}
                        />
                      }
                      checkedIcon={<BookmarkIcon fontSize="small" />}
                      checked={this.state.saved}
                    />
                  </div>
                  <div
                    className="des-icon"
                    title="Download PDF"
                    onClick={this.handleCVDownload}
                  >
                    {this.state.data ? (
                      <PDFDownloadLink
                        document={
                          <PdfDocument
                            graphBase64={this.state.graphBase64}
                            teams={this.state.teams}
                            player={this.state.player}
                            name={
                              this.state.data ? this.state.data.sentTo : "name"
                            }
                          />
                        }
                        fileName={player.name + " Info.pdf"}
                      >
                        {({ blob, url, loading, error }) =>
                          !loading && (
                            <Image
                              src={require("../../../assets/images/document.svg")}
                            />
                          )
                        }
                      </PDFDownloadLink>
                    ) : null}
                  </div>
                </div>
                <div className="row m-0 user-name">
                  <h2>{player.name}</h2>
                </div>
                <div className="row m-0 user-name">
                  <p>{player.position[0]}</p>
                </div>
              </div>
              <Grid container style={{ justifyContent: "space-between" }}>
                <div className="playerSPec">
                  <Image src={require("../../../assets/images/height.svg")} />
                  <p className="height m-0">Height</p>
                  <p className="he m-0">{player.height} cm</p>
                </div>
                <div className="playerSPec">
                  <Image src={require("../../../assets/images/diet.svg")} />
                  <p className="height m-0">Weight</p>
                  <p className="he m-0">{player.weight} kg</p>
                </div>
                <div className="playerSPec">
                  <Image src={require("../../../assets/images/calendar.svg")} />
                  <p className="height m-0">DOB</p>
                  <p className="he m-0">
                    {dayjs(player.age).format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="playerSPec">
                  <Image src={require("../../../assets/images/rugby.svg")} />
                  <p className="height m-0">Team</p>
                  <p className="he m-0">
                    {
                      this.state.teams.find((team) => team.id === player.teamId)
                        .name
                    }
                  </p>
                </div>
                <div className="playerSPec">
                  <Image src={require("../../../assets/images/rating.svg")} />
                  <p className="height m-0">Status</p>
                  <p className="he m-0">{player.status}</p>
                </div>
                <div className="playerSPec">
                  <Image src={require("../../../assets/images/medal.svg")} />
                  <p className="height m-0">Honours</p>
                  <p className="he m-0">{player.honors}</p>
                </div>
                <div className="playerSPec playerSPec7">
                  <Image src={require("../../../assets/images/contract.svg")} />
                  <p className="height m-0">OOC</p>
                  <p className="he m-0">{player.ooc}</p>
                </div>
              </Grid>
            </div>
          </div>

          <div className="container-fluid bio-container p-section">
            <div className="container">
              <div className="row">
                <div className="col-md-3 col-12">
                  <h6 className="p-section-heading">Bio</h6>
                </div>
                <div className="col-md-9 col-12 p-section-des">
                  <p>{player.bio}</p>
                </div>
              </div>
            </div>
          </div>

          {player.statistics?.length && (
            <div className="container graph-container p-section">
              <div className="row">
                <div className="col-md-3 col-12">
                  <h6 className="p-section-heading">Performance</h6>
                  <p className="p-section-subheading">
                    Vs average players in the same league and position
                  </p>
                </div>
                <div className="col-md-9 col-sm-12" style={{ marginTop: -100 }}>
                  <Graph
                    labels={player.statistics.map((stat) => stat.title)}
                    averageValues={player.statistics.map(
                      (stat) => stat.averageValue
                    )}
                    playerValues={player.statistics.map(
                      (stat) => stat.playerValue
                    )}
                    getGraphBase64={(base64) => {
                      if (!this.state.graphBase64) {
                        this.setState({ graphBase64: base64 });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {player.appearances?.length && (
            <div className="container p-section">
              <div className="row">
                <div className="col-md-3 col-sm-12">
                  <h6 className="p-section-heading">Appearances</h6>
                </div>

                {player.appearances.map((appearance, i) => (
                  <div key={i} className="col-md-3 col-sm-4 col-xs-6">
                    <p className="p-section-des-heading">{appearance.year}</p>
                    <p className="p-section-des-details">{appearance.value1}</p>
                    <p className="p-section-des-details">{appearance.value2}</p>
                    <p className="p-section-des-details">{appearance.value3}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="container p-section">
            <div className="row">
              <div className="col-md-3 col-xs-12">
                <h6 className="p-section-heading">History</h6>
              </div>
              <div className="col-md-3 col-xs-4">
                <p className="p-section-des-heading">Current Club</p>
                <p className="p-section-des-details">
                  {
                    this.state.teams.find((team) => team.id === player.teamId)
                      .name
                  }{" "}
                  ({player?.currentTeamAppearance || 0})
                </p>
              </div>
              <div className="col-md-3 col-xs-4">
                <div>
                  <p className="p-section-des-heading">Previous clubs</p>
                  {player.previousTeams.map((team, index) => {
                    const pTeam = this.state.teams.find(
                      (t) => t.id === team.teamId
                    );

                    return (
                      <p key={index} className="p-section-des-details">
                        {pTeam?.name}({team.appearance ? team.appearance : 0})
                      </p>
                    );
                  })}
                </div>
              </div>
              <div className="col-md-3 col-xs-4">
                <div>
                  <p className="p-section-des-heading">Country</p>
                  <p className="p-section-des-details">{player.country}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="container p-section no-border">
            <div className="row">
              <div className="col-12">
                <h6 className="p-section-heading">Others also viewed</h6>
              </div>
              <div className="row other-cards">
                {this.state.othersViewedPlayers.map((pl, index) => {
                  // checking if player is already saved
                  pl.saved = this.state.data.savedPlayers.some(
                    (playerId) => playerId === pl.id
                  );

                  return (
                    <div
                      key={index}
                      className="col-md-3 col-sm-6 col-xs-12 card"
                      style={{ width: "18rem", background: "transparent" }}
                    >
                      <NavLink
                        to={{
                          pathname: "/app/profile/" + pl.id,
                        }}
                        onClick={() => {
                          this.setState({ player: pl, saved: pl.saved });
                          window.scrollTo(0, 0);
                        }}
                        style={{ color: "#FFC65B", textDecoration: "none" }}
                      >
                        <Image
                          className="card-img-top"
                          src={
                            pl.profileImage
                              ? pl.profileImage
                              : require("./../../../assets/images/dp-placeholder.png")
                          }
                          alt="Card image cap"
                        />
                        <div className="card-body">
                          <h5 className="card-title">{pl.name}</h5>
                          <p className="card-text">{pl.bio}</p>
                        </div>
                        <div className="card-body card-body-bottom p-0">
                          <span className="card-link">
                            {pl.country && pl.country.toUpperCase()}
                          </span>{" "}
                          -
                          <span className="card-link">
                            {pl.team && pl.team.toUpperCase()}
                          </span>
                        </div>
                      </NavLink>
                      <ThemeProvider theme={outerTheme}>
                        <div className="bottom-icon" title="Save Player">
                          <Checkbox
                            style={{ padding: 0 }}
                            inputProps={{
                              "aria-label": "primary checkbox",
                            }}
                            checkedIcon={<BookmarkIcon fontSize="small" />}
                            icon={
                              <BookmarkBorderIcon
                                fontSize="small"
                                style={{ color: "#FFC65B" }}
                              />
                            }
                            onClick={() => this.handleSavePlayer(pl)}
                            checked={
                              pl.id === this.state.player.id
                                ? this.state.saved
                                : pl.saved
                            }
                          />
                        </div>
                      </ThemeProvider>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </ThemeProvider>
      </div>
    ) : (
      <div className="parentDisable">
        <div className="overlay-box">
          <DotLoader size={150} color="#FFC65B" loading />
        </div>
      </div>
    );
  }
}
export default PlayerProfile;
