import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
  Font,
} from "@react-pdf/renderer";
import dayjs from "dayjs";

Font.register({
  family: "Biotif",
  src: require("../../../assets/fonts/Biotif-Regular.ttf"),
});
Font.register({
  family: "Biotif-Bold",
  src: require("../../../assets/fonts/Biotif-Bold.ttf"),
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#171717",
    paddingHorizontal: "7%",
    paddingVertical: "5%",
  },
  container: {},
  bgImage: {
    width: "130%",
    position: "absolute",
    top: "55%",
    left: "-10%",
  },
  section: {
    display: "flex",
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: 0,
    borderTopWidth: 2,
    borderTopColor: "#FFC65B",
  },
  column1: {
    flex: 3,
  },
  column2: {
    flex: 7,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  column: {
    flex: 1,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingHorizontal: 0,
  },
  logo: {
    width: 60,
    marginBottom: 10,
  },
  name: {
    color: "#FFF",
    fontFamily: "Biotif-Bold",
    fontSize: 24,
    marginVertical: 5,
  },
  position: {
    color: "#4f4d4d",
    fontFamily: "Biotif-Bold",
    fontSize: 14,
  },
  confidential: {
    maxWidth: 100,
    fontSize: 10,
    color: "white",
    fontFamily: "Biotif-Bold",
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginTop: 16,
    backgroundColor: "#FFC65B",
    borderTopRightRadius: 3,
    borderTopLeftRadius: 3,
  },
  imageContainer: {
    height: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  image: {
    width: 120,
    objectFit: "contain",
  },
  logoWithoutText: {
    width: 15,
    position: "absolute",
    bottom: 5,
    right: 5,
  },
  headingContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 7,
  },
  heading: {
    color: "#FFF",
    fontFamily: "Biotif-Bold",
    fontSize: 14,
    marginBottom: 7,
  },
  text: {
    color: "#FFF",
    fontFamily: "Biotif",
    fontSize: 8,
    marginBottom: 7,
  },
  textBold: {
    color: "#FFF",
    fontFamily: "Biotif-Bold",
    fontSize: 8,
    marginBottom: 7,
  },
  stats: {
    backgroundColor: "#292929",
    border: 1,
    borderColor: "lightgray",
    borderRadius: 8,
    paddingHorizontal: 15,
    paddingTop: 15,
    paddingBottom: 20,
  },
  small: {
    color: "#FFF",
    fontFamily: "Biotif",
    fontSize: 7,
    marginLeft: 15,
    marginTop: 5,
  },
  video: {
    flex: 1,
    flexDirection: "row",
  },
  videoImg: {
    height: 100,
    objectFit: "cover",
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  videoColumn1: {
    justifyContent: "center",
    alignItems: "center",
  },
  footer: {
    position: "absolute",
    bottom: 15,
    right: 50,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  footerLogo: {
    width: 60,
    marginLeft: 20,
  },
  m0: {
    margin: 0,
  },
  p0: {
    padding: 0,
  },
});

export function PdfDocument(props) {
  const { player, teams, graphBase64 } = props;

  const currentTeam = teams.find((team) => team.id === player.teamId);
  const previousTeams = player.previousTeams.map((pTeam) => {
    const obj = teams.find((team) => team.id === pTeam.teamId);
    return obj ? { ...pTeam, name: obj.name } : pTeam;
  });

  const nameArr = player?.name?.trim()?.split(" ");
  const lastName = nameArr?.pop();
  const firstName = nameArr?.join(" ");

  return player &&
    teams.length > 0 &&
    ((!!player.statistics?.length && !!graphBase64) ||
      !player.statistics?.length) ? (
    <Document>
      <Page style={styles.page} size="A4">
        <View style={styles.container}>
          <View style={styles.header}>
            <View>
              <Image
                style={styles.logo}
                source={require("../../../assets/images/rugby-logo.png")}
              />
              <Text style={styles.name}>
                {firstName} <Text style={{ color: "#FFC65B" }}>{lastName}</Text>
              </Text>
              <Text style={styles.position}>{player.position.join(" | ")}</Text>
              <Text style={styles.confidential}>CONFIDENTIAL</Text>
            </View>

            <View style={styles.imageContainer}>
              <Image
                style={styles.image}
                src={{
                  uri: player?.profileImage,
                  method: "GET",
                }}
              />

              <Image
                style={styles.logoWithoutText}
                src={require("../../../assets/images/logo-without-text.png")}
              />
            </View>
          </View>

          <View style={styles.section}>
            <View style={styles.column1}>
              <Text style={styles.heading}>Statistics</Text>
              <View style={{ ...styles.stats, height: 170 }}>
                <View style={styles.row}>
                  <Text style={[styles.column, styles.textBold]}>Height -</Text>
                  <Text style={[styles.column, styles.text]}>
                    {player.height}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={[styles.column, styles.textBold]}>Weight -</Text>
                  <Text style={[styles.column, styles.text]}>
                    {player.weight} kg
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={[styles.column, styles.textBold]}>DoB -</Text>
                  <Text style={[styles.column, styles.text]}>
                    {dayjs(player.age).format("DD/MM/YYYY")}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={[styles.column, styles.textBold]}>Club -</Text>
                  <Text style={[styles.column, styles.text]}>
                    {currentTeam?.name || ""}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={[styles.column, styles.textBold]}>Status -</Text>
                  <Text style={[styles.column, styles.text]}>
                    {player.status ? "Active" : "InActive"}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={[styles.column, styles.textBold]}>Honours -</Text>
                  <Text style={[styles.column, styles.text]}>
                    {player.honors}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={[styles.column, styles.textBold]}>OOC -</Text>
                  <Text style={[styles.column, styles.text]}>{player.ooc}</Text>
                </View>
              </View>
            </View>

            {!!graphBase64 ? (
              <View style={{ ...styles.column2, marginLeft: 10 }}>
                <View style={styles.headingContainer}>
                  <Text style={[styles.heading, styles.m0]}>Performance</Text>
                  <Text style={styles.small}>
                    Vs average players in the same league and position
                  </Text>
                </View>

                <View style={{ ...styles.stats, height: 170 }}>
                  <Image src={graphBase64} />
                </View>
              </View>
            ) : player.BGImageUrl ? (
              <View style={{ ...styles.column2, marginLeft: 10 }}>
                <View
                  style={{
                    ...styles.stats,
                    height: 170,
                    padding: 0,
                    marginTop: 25,
                  }}
                >
                  <Image
                    style={{ height: 170, borderRadius: 8 }}
                    src={player.BGImageUrl}
                  />
                </View>
              </View>
            ) : (
              <View style={{ ...styles.column2, marginLeft: 10 }}>
                <View
                  style={{
                    ...styles.stats,
                    height: 170,
                    padding: 0,
                    marginTop: 25,
                  }}
                >
                  <Image
                    style={{ height: 170, borderRadius: 8 }}
                    src={require("../../../assets/images/background.png")}
                  />
                </View>
              </View>
            )}
          </View>

          <View style={styles.section}>
            <View style={styles.column1}>
              <Text style={styles.heading}>Biography</Text>
            </View>
            <View style={styles.column2}>
              <Text style={styles.text}>{player.bio}</Text>
            </View>
          </View>

          {!!player.appearances?.length && (
            <View style={styles.section}>
              <View style={styles.column1}>
                <Text style={styles.heading}>Appearances</Text>
              </View>
              <View style={[styles.column2, styles.row]}>
                {player.appearances?.map((appearance, i) => (
                  <View key={i} style={styles.column}>
                    <Text style={styles.textBold}>{appearance.year}</Text>
                    <Text style={styles.text}>{appearance.value1}</Text>
                    <Text style={styles.text}>{appearance.value2}</Text>
                    <Text style={styles.text}>{appearance.value3}</Text>
                  </View>
                ))}
              </View>
            </View>
          )}

          <View style={styles.section}>
            <View style={styles.column1}>
              <Text style={styles.heading}>History</Text>
            </View>
            <View style={[styles.column2, styles.row]}>
              <View style={styles.column}>
                <Text style={styles.textBold}>Current club</Text>
                <Text style={styles.text}>{`${currentTeam?.name || ""} (${
                  player.currentTeamAppearance
                })`}</Text>
              </View>

              <View style={styles.column}>
                <Text style={styles.textBold}>Previous clubs</Text>
                {previousTeams.map((pTeam, i) => (
                  <Text key={i} style={styles.text}>{`${pTeam?.name || ""} (${
                    pTeam.appearance
                  })`}</Text>
                ))}
              </View>

              <View style={styles.column}>
                <Text style={styles.textBold}>Country</Text>
                <Text style={styles.text}>{player?.country || ""}</Text>
              </View>
            </View>
          </View>

          {player.videoUrl && (
            <View style={styles.section}>
              <View style={[styles.video, styles.stats, styles.p0]}>
                <View style={[styles.column1, styles.videoColumn1]}>
                  <Link src={player.videoUrl}>
                    <View style={styles.row}>
                      <Image
                        style={{
                          height: 14,
                          width: 14,
                          marginRight: 7,
                        }}
                        src={require("../../../assets/images/play-icon.png")}
                      />
                      <Text
                        style={{
                          ...styles.heading,
                          marginBottom: 0,
                          marginTop: 2,
                        }}
                      >
                        Play video reel
                      </Text>
                    </View>
                  </Link>
                </View>
                <View style={styles.column2}>
                  <Link src={player.videoUrl}>
                    <Image
                      style={styles.videoImg}
                      src={require("../../../assets/images/pdf-video-img.png")}
                    />
                  </Link>
                </View>
              </View>
            </View>
          )}
        </View>
      </Page>
    </Document>
  ) : (
    <Document>
      <Page></Page>
    </Document>
  );
}
