import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Bar } from "react-chartjs-2";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "440px",
    width: "100%",
    minWidth: "825px",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
}));

export default function Graph(props) {
  const classes = useStyles();

  const formatLabel = (str, maxwidth) => {
    var sections = [];
    var words = str.split(" ");
    var temp = "";

    words.forEach(function (item, index) {
      if (temp.length > 0) {
        var concat = temp + " " + item;

        if (concat.length > maxwidth) {
          sections.push(temp);
          temp = "";
        } else {
          if (index === words.length - 1) {
            sections.push(concat);
            return;
          } else {
            temp = concat;
            return;
          }
        }
      }

      if (index === words.length - 1) {
        sections.push(item);
        return;
      }

      if (item.length < maxwidth) {
        temp = item;
      } else {
        sections.push(item);
      }
    });

    return sections;
  };

  const getPlayerValuesData = () => {
    const highestAValue = Math.max(...props.averageValues);
    const highestAValuePercentage = highestAValue / 100;

    return props.playerValues.map((val, i) => {
      const avgPercent = props.averageValues[i] / 100;
      const difference = (val / avgPercent - 100) * highestAValuePercentage;
      return highestAValue + difference;
    });
  };

  const getMaxScale = () => {
    const updatedPlayerValue = getPlayerValuesData();
    const maxPValue = Math.max(...updatedPlayerValue);
    const maxValue =
      maxPValue === 0
        ? Math.max(...props.averageValues)
        : Math.max(...updatedPlayerValue);
    return maxValue + (maxValue / 100) * 5;
  };

  const getAverageValueData = () => {
    const highestAValue = Math.max(...props.averageValues);
    return props.averageValues.map((val) => highestAValue);
  };

  const data = {
    labels: props.labels.map((label) => formatLabel(label, 9)),
    datasets: [
      {
        label: "Player Stat",
        data: getPlayerValuesData(),
        borderColor: "rgb(255, 198, 91)",
        backgroundColor: "rgba(255, 198, 91, 0.5)",
        borderWidth: { top: 2, bottom: 0, left: 0, right: 0 },
        barThickness: 20,
      },
      {
        label: "Average",
        data: getAverageValueData(),
        borderColor: "#696969",
        backgroundColor: "#696969",
        barThickness: 20,
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 30,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      yAxis: {
        display: false,
        max: getMaxScale() + 10,
        grid: {
          display: false,
        },
      },
      xAxis: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#fff",
          font: {
            size: 14,
            weight: "500",
          },
        },
      },
    },
    events: [],
    animation: {
      duration: 1,
      onComplete: function (obj) {
        let avgBarHeight = 0;
        let barStartPoint = 0;
        const ctx = obj.chart.ctx;
        ctx.textAlign = "center";
        ctx.fillStyle = "#fff";
        ctx.textBaseline = "bottom";
        ctx.font = "600 13px Biotif-Regular";
        // Rendering label on the bars.
        const playerValuesMeta = obj.chart.getDatasetMeta(0);
        playerValuesMeta.data.forEach(function (bar, i) {
          ctx.fillText(props.playerValues[i], bar.x, bar.y - 5);
        });
        ctx.fillStyle = "#737171";
        const averageValuesMeta = obj.chart.getDatasetMeta(1);
        averageValuesMeta.data.forEach(function (bar, i) {
          ctx.fillText(props.averageValues[i], bar.x, bar.y - 5);
          if (avgBarHeight === 0) avgBarHeight = bar.y;
          if (barStartPoint === 0) barStartPoint = bar.base;
        });
        // Adding average text.
        ctx.fillStyle = "#fff";
        ctx.fillText("Average ", 25, avgBarHeight + 5);
        // Adding horizontal average line on graph.
        ctx.beginPath();
        ctx.strokeStyle = "#595858";
        ctx.moveTo(67, avgBarHeight);
        ctx.lineTo(ctx.canvas.clientWidth - 38, avgBarHeight);
        ctx.stroke();
        // Adding line to end.
        ctx.beginPath();
        ctx.strokeStyle = "#fff";
        ctx.moveTo(67, barStartPoint);
        ctx.lineTo(ctx.canvas.clientWidth - 38, barStartPoint);
        ctx.stroke();
        props.getGraphBase64(obj.chart.canvas.toDataURL("image/png"));
      },
    },
  };

  return (
    <div className={classes.root}>
      <Bar data={data} height={400} options={chartOptions} />
    </div>
  );
}
