import { db } from "../../util/firebase";

export const login = async (link, UserCallBack) => {
  const linkSnapshot = await db.collection("links").doc(link.id).get();
  const linkData = linkSnapshot.data();

  if (linkData) {
    if (linkData.password !== link.password) {
      UserCallBack("Password Incorrect");
    } else {
      let activityObj = {
        type: "LOGIN",
        date: new Date(),
        link: link.id,
      };
      await db.collection("activity").add(activityObj);
      localStorage.setItem("linkAuth", true);
      localStorage.setItem("linkId", link.id);
      localStorage.setItem("prevLink", link.id);
      UserCallBack("");
    }
  } else {
    UserCallBack("The link you are trying to access doesn't exist");
  }
};

export const logout = (callBack) => {
  localStorage.setItem("linkAuth", false);
  callBack();
};
