import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "react-bootstrap";
import "react-responsive-modal/styles.css";
import { Paper, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DotLoader from "react-spinners/DotLoader";
import Modal from "react-modal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./../theme/checkTheme";
import Accordion from "./Accordian";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { db } from "../../../util/firebase";
import { SendLink } from "../../../firebase/admin/links";

const generator = require("generate-password");

const styles = {
  searchBar: {
    width: "250%",
    backgroundColor: "transparent",
  },
};

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playersList: [],
      email: "",
      name: "",
      teams: [],
      filteredData: [],
      filteredPlayer: [],
      filter: "",
      loading: true,
      showModal: false,
    };
    Modal.setAppElement("#root");
  }

  onOpenModal = () => {
    this.setState({ showModal: true });
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  selectUnselectAll = (teamId) => {
    let teams = [...this.state.teams];
    const index = teams.findIndex((team) => team.teamId === teamId);

    if (!teams[index].allSelected) {
      teams[index].players.forEach((player) => {
        player.checked = true;
      });
      teams[index].allSelected = true;
      teams[index].selectedPlayers = [];
    } else {
      teams[index].players.forEach((player) => {
        player.checked = false;
      });
      teams[index].allSelected = false;
      teams[index].selectedPlayers = [];
    }

    this.setState({ teams: teams });
  };

  checkBoxClicked = (player) => {
    player.checked = !player.checked;

    // let teams = cloneDeep(this.state.teams);
    let teams = [...this.state.teams];
    const index = teams.findIndex((team) => team.teamId === player.teamId);

    let selectedPlayersIds = [];
    // Getting checked players Ids
    teams[index].players.forEach((player) => {
      if (player.checked) {
        selectedPlayersIds.push(player.id);
      }
    });

    if (selectedPlayersIds.length === teams[index].players.length) {
      // All players are selected
      teams[index].allSelected = true;
      teams[index].selectedPlayers = [];
    } else {
      // Not all players are selected
      teams[index].allSelected = false;
      teams[index].selectedPlayers = selectedPlayersIds;
    }

    this.setState({ teams: teams });
  };

  async componentDidMount() {
    let teams = [];
    let playersList = [];
    let filteredData = [];
    // getting all players from firebase
    const playersSnapshot = await db.collection("players").get();
    playersSnapshot.docs.forEach((doc) => {
      const {
        name,
        position,
        bio,
        weight,
        height,
        caps,
        teamId,
        country,
        honors,
        ooc,
        hidden,
        deleted,
        age,
        status,
        profileImage,
        videoUrl,
        previousTeams,
      } = doc.data();
      if (!hidden && !deleted) {
        playersList.push({
          id: doc.id,
          name,
          position,
          bio,
          weight,
          height,
          caps,
          teamId,
          country,
          honors,
          ooc,
          age,
          status,
          profileImage,
          videoUrl,
          checked: true,
          previousTeams,
        });
        filteredData.push(name);
      }
    });
    // getting all teams from firebase
    const teamsSnapshot = await db.collection("teams").get();
    teamsSnapshot.docs.forEach((doc) => {
      const { name, deleted } = doc.data();
      if (!deleted) {
        teams.push({
          id: doc.id,
          name,
          deleted,
        });
      }
    });
    // sorting teams alphabetically
    teams.sort((a, b) => (a.name > b.name ? 1 : -1));
    // adding team players to team details
    let data = teams.map((team) => {
      return {
        teamId: team.id,
        teamName: team.name,
        teamDeleted: team.deleted,
        players: playersList.filter((player) => player.teamId === team.id),
        selectedPlayers: [],
        allSelected: true,
      };
    });
    // setting state
    this.setState({
      loading: false,
      playersList: playersList,
      filteredData: filteredData,
      teams: data,
    });
  }

  handleSearch = (e, value) => {
    this.setState({ filter: value ? value : "" });
    let list = [];
    if (value != null) {
      this.state.playersList.forEach((player) => {
        if (
          player.name === value ||
          player.name.toLowerCase().includes(value.toLowerCase())
        ) {
          list.push(player);
        }
      });
    }
    this.setState({ filteredPlayer: list });
  };

  sendInvite = () => {
    // this.setState({ loading: true });
    const password = generator.generate({
      length: 10,
      numbers: true,
    });
    const data = this.state.teams
      .map((team) => {
        if (team.allSelected || team.selectedPlayers.length > 0) {
          return {
            teamId: team.teamId,
            selectedPlayers: team.selectedPlayers,
          };
        }
      })
      .filter((x) => x !== undefined);

    const link = {
      teams: data,
      password: password,
      sentTo: this.state.name,
      emailAddress: this.state.email,
      savedPlayers: [],
      CVDownloads: [],
      dateSent: new Date(),
    };
    // console.log("link object is", link);
    if (data.length === 0) {
      //alert("Select some players")
      this.setState({ loading: false });
      return;
    }
    SendLink(link, () => {
      this.setState({ loading: false, showModal: true });
    });
  };

  render() {
    const { loading, showModal, filteredData, filter, filteredPlayer, teams } =
      this.state;

    return (
      <ThemeProvider theme={theme}>
        <div className={loading ? "parentDisable" : ""}>
          <div className="overlay-box">
            <DotLoader size={150} color="#FFC65B" loading={loading} />
          </div>
        </div>
        <div>
          <div className={showModal ? "modal-overlay" : ""}>
            <Modal
              className="modal-link"
              overlayClassName="mdl-overlay"
              isOpen={showModal}
              onRequestClose={this.onCloseModal}
            >
              <div className="modal-body">
                <p className="modal-text">
                  Success! the person has been invited
                </p>
                <Button
                  className="modal-btn"
                  type="submit"
                  onClick={() => {
                    this.setState({ showModal: false });
                  }}
                >
                  DISMISS
                </Button>
              </div>
            </Modal>
          </div>
        </div>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <p
              className="text pointsHeading"
              style={{ color: "#FFC65B", paddingTop: "20px" }}
            >
              1. Choose which players the recipient will see
            </p>
          </Grid>
          <Grid item xs={12} sm={6} lg={5} style={{ marginTop: "5px" }}>
            <Paper
              style={{
                padding: 5,
                width: "85%",
                background: "rgb(255, 255, 255, 0.26)",
              }}
            >
              <Autocomplete
                style={{ borderRadius: "6px", width: "95%" }}
                freeSolo
                onChange={(e, value) => {
                  this.handleSearch(e, value);
                }}
                options={filteredData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="search-btn"
                    label="Search"
                    margin="normal"
                    onChange={(e, value) => {
                      this.handleSearch(e, e.target.value);
                    }}
                    value={filter}
                  />
                )}
              />
            </Paper>
          </Grid>

          {filter !== ""
            ? filteredPlayer.map((player, index) => {
                return (
                  <Grid key={index} item xl={2} lg={2} md={3} sm={4} xs={6}>
                    <Grid container spacing={0} direction="row">
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ color: "white" }}
                          />
                        }
                        inputProps={{ "aria-label": "primary checkbox" }}
                        onChange={(e) => this.checkBoxClicked(player)}
                        checked={player.checked}
                      />
                      <p className="accText">{player.name}</p>
                    </Grid>
                  </Grid>
                );
              })
            : null}

          <Grid item className="teamsGrid">
            {teams.map((team, index) => {
              return (
                <Accordion
                  key={index}
                  team={team}
                  handleCheckBoxClick={this.checkBoxClicked}
                  handleSelectUnselectAll={this.selectUnselectAll}
                />
              );
            })}
          </Grid>

          <Grid item>
            <p
              className="text pointsHeading"
              style={{ color: "#FFC65B", marginTop: "25px" }}
            >
              2. Add their contact details
            </p>
          </Grid>

          <Grid item>
            <Grid
              container
              spacing={2}
              justify="flex-start"
              className="detailsROw"
            >
              <Grid item sm={6} xs={12} md={4} lg={4} xl={4}>
                <Paper
                  className="full-input borderRadius-left"
                  style={{ backgroundColor: "rgb(255, 255, 255, 0.26)" }}
                >
                  <label className="floating-label" htmlFor="name">
                    EMAIL ADDRESS
                  </label>
                  <input
                    className="input"
                    onChange={(e) => {
                      this.setState({ email: e.target.value });
                    }}
                    type="text"
                    placeholder="abcdef@gmail.com"
                  ></input>
                </Paper>
              </Grid>
              <Grid item sm={6} xs={12} md={4} lg={4} xl={4}>
                <Paper
                  className="full-input borderRadius-right"
                  style={{
                    backgroundColor: "rgb(255, 255, 255, 0.26)",
                    boxShadow: "none",
                  }}
                >
                  <div className="borderLeft-div">
                    <label className="floating-label">FIRST NAME</label>
                    <input
                      className="input"
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                      }}
                      type="text"
                      placeholder="Brad"
                    ></input>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className="btnRow">
            <Button
              className="send-button"
              type="submit"
              onClick={this.sendInvite}
            >
              SEND INVITE
            </Button>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(Index);
