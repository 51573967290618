import React  from 'react';
import { AuthConsumer } from '../context';

const protectedComponent = (Component, FallbackComponent = null) => (
  <AuthConsumer>
    {({ linkCheck }) => (linkCheck() ? Component : FallbackComponent)}
  </AuthConsumer>
);

export default protectedComponent;
