import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { Image } from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    backgroundColor: "rgb(255, 255, 255, 0.26)",
    height: 120,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  input: {
    display: "none",
  },
});

class VideoUploadCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: this.props.videoFile,
      fileName: "File Name",
    };
  }

  componentDidMount() {
    if (this.props.imageFile == null) {
      this.setState({selectedFile: null})
    }
  }

  handleUploadClick = (e) => {
    this.setState({ fileName: e.target.files[0].name });
    this.props.handleVideoAsFile(e);
    const reader = new FileReader();
    reader.onloadend = function (e) {
      this.setState({
        selectedFile: [reader.result],
      });
    }.bind(this);
    this.setState({
      selectedFile: e.target.files[0],
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {this.state.selectedFile || this.props.videoFile ? (
          <Image
            className="tickVideoPlayer"
            src={require("../../../assets/images/done_tick.svg")}
          />
        ) : (
          <></>
        )}
        <label htmlFor="contained-button-file1" style={{ cursor: "pointer" }}>
          <Card className={classes.root}>
            <CardContent className="content" style={{ width: "100%" }}>
              <Grid
                container
                justify="center"
                alignItems="flex-start"
                direction="column"
              >
                <input
                  accept="video/*"
                  className={classes.input}
                  id="contained-button-file1"
                  multiple
                  type="file"
                  onChange={this.handleUploadClick}
                />
                <Typography
                  variant="h6"
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Biotif-regular",
                    fontSize: "14px",
                  }}
                  display="block"
                  gutterBottom
                >
                  Video Reel
                </Typography>
                <Typography
                  variant="caption"
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Biotif-regular",
                    cursor: "pointer",
                  }}
                  display="block"
                  gutterBottom
                >
                  Click to add link or upload video
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </label>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(VideoUploadCard);
