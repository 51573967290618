import { db } from "../../util/firebase";
import ActivityInterface from "../../interfaces/activity";

export const fetchSavedPlayers = async (linkId, UserCallBack) => {
  let list = [];
  let teams = [];
  // fetching all teams
  const teamsSnapshot = await db.collection("teams").get();
  teamsSnapshot.docs.forEach((doc) => {
    const { name, deleted } = doc.data();
    teams.push({
      id: doc.id,
      name,
      deleted,
    });
  });

  const link = await db.collection("links").doc(linkId).get();
  const data = link.data();

  for (const playerId of data.savedPlayers) {
    const player = await db.collection("players").doc(playerId).get();
    const {
      name,
      position,
      bio,
      weight,
      height,
      caps,
      teamId,
      country,
      currentTeamAppearance,
      honors,
      ooc,
      age,
      status,
      profileImage,
      videoUrl,
      previousTeams,
      BGImageUrl,
      hidden,
      deleted,
    } = player.data();
    if (!hidden && !deleted) {
      const teamObj = teams.find((x) => x.id === teamId);
      list.push({
        id: player.id,
        name,
        position,
        bio,
        weight,
        height,
        caps,
        teamId,
        team: teamObj && !teamObj.deleted ? teamObj.name : "",
        country,
        honors,
        ooc,
        age,
        status,
        profileImage,
        videoUrl,
        previousTeams,
        BGImageUrl,
        currentTeamAppearance,
      });
    }
  }
  // executing callback
  UserCallBack(list);
};

export const fetchData = async (linkId, UserCallBack) => {
  let list = [];
  let teams = [];
  let players = [];
  // fetching all teams
  const teamsSnapshot = await db.collection("teams").get();
  teamsSnapshot.docs.forEach((doc) => {
    const { name, deleted } = doc.data();
    teams.push({
      id: doc.id,
      name,
      deleted,
    });
  });
  // fetching all players
  const playersSnapshot = await db.collection("players").get();
  playersSnapshot.docs.forEach((doc) => {
    const {
      name,
      position,
      bio,
      weight,
      height,
      caps,
      teamId,
      country,
      currentTeamAppearance,
      honors,
      ooc,
      age,
      status,
      profileImage,
      videoUrl,
      previousTeams,
      BGImageUrl,
      hidden,
      deleted,
    } = doc.data();

    if (!hidden && !deleted) {
      const teamObj = teams.find((x) => x.id === teamId);
      players.push({
        id: doc.id,
        name,
        position,
        bio,
        weight,
        height,
        caps,
        teamId,
        team: teamObj && !teamObj.deleted ? teamObj.name : "",
        country,
        honors,
        ooc,
        age,
        status,
        profileImage,
        videoUrl,
        previousTeams,
        BGImageUrl,
        currentTeamAppearance,
      });
    }
  });

  const link = await db.collection("links").doc(linkId).get();
  const data = link.data();

  if (data.playersList) {
    // this is an old link
    data.playersList.forEach((player) => {
      const filteredPlayers = players.filter((p) => p.id === player.id);
      list = list.concat(filteredPlayers);
    });
    // executing user callback
    data.playersList = list;
    UserCallBack({ ...data });
  } else if (data.teams) {
    // this is new link
    // getting teams with no selected player
    const wholeTeams = data.teams.filter(
      (team) => team.selectedPlayers.length === 0
    );
    // getting teams with selected players
    const selectPlayersTeams = data.teams.filter(
      (team) => team.selectedPlayers.length > 0
    );

    if (wholeTeams.length > 0) {
      const teamIds = wholeTeams.map((team) => team.teamId);

      teamIds.forEach((teamId) => {
        const filteredPlayers = players.filter(
          (player) => player.teamId === teamId
        );
        list = list.concat(filteredPlayers);
      });
    }

    if (selectPlayersTeams.length > 0) {
      const playersIds = [];
      selectPlayersTeams.forEach((team) => {
        playersIds.concat(team.selectedPlayers);
      });

      playersIds.forEach((playersId) => {
        const filteredPlayers = players.filter(
          (player) => player.id === playersId
        );
        list = list.concat(filteredPlayers);
      });
    }
    // executing user callback
    data["playersList"] = list;
    UserCallBack({ ...data });
  }
};

export const getAgents = (callBackFunction) => {
  const agentList = [];
  const agentRef = db.collection("agents");
  agentRef.onSnapshot((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      const { name, email } = doc.data();
      agentList.push({
        id: doc.id,
        name,
        email,
      });
      callBackFunction(agentList);
    });
  });
};

export const savePlayer = async (player, linkId, callBack) => {
  let saved = [];
  let activityObj = {
    type: "SAVE_PLAYER",
    player: player.name,
    date: new Date(),
    link: linkId,
  };

  db.collection("links")
    .doc(linkId)
    .get()
    .then((snapshot) => {
      const data = snapshot.data();
      saved = data.savedPlayers;
      const index = saved.findIndex((p) => p === player.id);
      if (index !== -1) {
        saved.splice(index, 1);
      } else {
        saved.push(player.id);
        // storing activity when user saved player
        db.collection("activity")
          .add(activityObj)
          .then(function (docRef) {
            console.log("Activity stored");
          });
      }
      const ref = db.collection("links").doc(linkId);
      ref.update({ savedPlayers: saved }).then(callBack());
    });
};

export const downloadCV = async (player, linkId, callBack) => {
  let downloadCV = [];
  let activityObj = Object.create(ActivityInterface);
  activityObj = {
    type: "DOWNLOAD_PLAYER_PROFILE",
    player: player.name,
    date: new Date(),
    link: linkId,
  };
  db.collection("links")
    .doc(linkId)
    .get()
    .then((snapshot) => {
      const data = snapshot.data();
      downloadCV = data.CVDownloads;
      const index = downloadCV.findIndex((p) => p === player.id);
      if (index === -1) {
        downloadCV.push(player.id);
        const ref = db.collection("links").doc(linkId);
        ref.update({ CVDownloads: downloadCV }).then(callBack());
        db.collection("activity")
          .add(activityObj)
          .then(function (docRef) {
            console.log("Activity stored");
          });
      }
    });
};
