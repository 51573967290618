import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { TextField, Paper } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import DotLoader from "react-spinners/DotLoader";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { db } from "../../../util/firebase";
import Table from "./table";

const styles = {
  searchBar: {
    width: "230%",
  },
};

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000",
      light: "#2B2B2B",
      dark: "#FFFFFF",
    },
    secondary: {
      main: "#FFC65B",
    },
    grey: {
      main: "black",
    },
    action: {
      main: "black",
      light: "black",
      dark: "black",
    },
  },
  overrides: {
    MuiAutocomplete: {
      option: {
        borderBottom: "solid 1px rgb(57, 57, 57,0.15)",
        fontSize: "12px",
        fontFamily: "Biotif-Regular",
      },
      listbox: {
        padding: "10px",
      },
    },
  },
});

class EditPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBarValue: "",
      filteredData: [],
      showHidden: false,
      playersList: null,
      filteredPlayer: [],
      filter: "",
      loading: false,
    };
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  handleSearch = (e, value) => {
    let list = [];
    if (value != null) {
      this.state.playersList.forEach((player) => {
        if (
          player.name === value ||
          player.name.toLowerCase().includes(value.toLowerCase())
        ) {
          list.push(player);
        }
      });
    }
    this.setState({
      filter: value ? value : "",
      filteredPlayer: list,
      loading: false,
    });
    this.props.setStateChanged(true);
  };

  handleCheckbox = (event) => {
    this.setState({ showHidden: event.target.checked });
    if (!this.state.showHidden) {
      const refKey = db.collection("players");
      refKey.onSnapshot((querySnapshot) => {
        const list = [];
        const filteredData = [...this.state.filteredData];

        querySnapshot.forEach((doc) => {
          const {
            name,
            position,
            bio,
            weight,
            height,
            caps,
            teamId,
            country,
            BGImageUrl,
            honors,
            ooc,
            age,
            status,
            profileImage,
            videoUrl,
            previousTeams,
            hidden,
            deleted,
            dateAdded,
          } = doc.data();
          if (!deleted) {
            filteredData.push(name);
            list.push({
              id: doc.id,
              name,
              position,
              bio,
              weight,
              height,
              caps,
              teamId,
              country,
              honors,
              ooc,
              age,
              status,
              profileImage,
              videoUrl,
              previousTeams,
              hidden,
              deleted,
              dateAdded,
              BGImageUrl,
            });
          }
        });
        this.setState({ playersList: list, filteredData: filteredData });
      });
    } else {
      this.state.playersList.forEach((player) => {
        if (player.hidden) {
          var index = this.state.playersList.indexOf(player);
          if (index !== -1) {
            let playersList = [...this.state.playersList];
            playersList.splice(index, 1);
            this.setState({ playersList: playersList });
          }
        }
      });
    }
  };

  async componentDidMount() {
    const refKey = db.collection("players");
    refKey.onSnapshot((querySnapshot) => {
      const list = [];
      let filteredData = [...this.state.filteredData];

      querySnapshot.forEach((doc) => {
        const {
          name,
          position,
          bio,
          weight,
          height,
          caps,
          teamId,
          currentTeamAppearance,
          country,
          BGImageUrl,
          honors,
          ooc,
          age,
          status,
          profileImage,
          videoUrl,
          previousTeams,
          hidden,
          deleted,
          dateAdded,
        } = doc.data();
        if (!deleted && !hidden) {
          filteredData.push(name);
          list.push({
            id: doc.id,
            name,
            position,
            bio,
            weight,
            height,
            caps,
            teamId,
            currentTeamAppearance,
            country,
            honors,
            ooc,
            age,
            status,
            profileImage,
            videoUrl,
            previousTeams,
            hidden,
            deleted,
            dateAdded,
            BGImageUrl,
          });
        }
      });
      this.setState({ playersList: list, filteredData: filteredData });
      this.props.setStateChanged(false);
    });
  }

  render() {
    return (
      <ThemeProvider theme={outerTheme}>
        <div className={this.state.loading ? "parentDisable" : ""}>
          <div className="overlay-box">
            <DotLoader
              size={150}
              color="#FFC65B"
              loading={this.state.loading}
            />
          </div>
        </div>
        <div style={{ padding: "25px 0px", boxShadow: "none" }}>
          <Grid
            container
            direction="row"
            justify="space-between"
            spacing={8}
            style={{ paddingBottom: 150 }}
          >
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Paper
                className="searchEdit"
                style={{ background: "rgb(255, 255, 255, 0.26)" }}
              >
                <Autocomplete
                  style={{ borderRadius: "6px", width: "95%" }}
                  freeSolo
                  onChange={(e, value) => {
                    this.handleSearch(e, value);
                  }}
                  options={this.state.filteredData}
                  renderInput={(params) => (
                    <TextField
                      className="search-btn editSearch"
                      {...params}
                      label="Search"
                      margin="normal"
                      onChange={(e, value) => {
                        this.handleSearch(e, e.target.value);
                      }}
                      value={this.state.filter}
                    />
                  )}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Grid container spacing={0} className="hiddenPlayer-div">
                <Grid item>
                  <p
                    style={{
                      color: "#FFFFFF",
                      padding: "10px",
                      fontSize: "14px",
                      margin: "2px",
                    }}
                  >
                    Show hidden players
                  </p>
                </Grid>
                <Grid item>
                  <Checkbox
                    icon={
                      <CheckBoxOutlineBlankIcon style={{ color: "white" }} />
                    }
                    checked={this.state.showHidden}
                    onChange={this.handleCheckbox.bind(this)}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Table
            playersList={
              this.state.filter === ""
                ? this.state.playersList
                : this.state.filteredPlayer
            }
          />
        </div>
      </ThemeProvider>
    );
  }
}

EditPlayer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditPlayer);
