import React, { useContext } from "react";
import { Image } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { logout } from "../../../firebase/app/login";
import AuthContext from "../../../auth/context";
import { NavLink } from "react-router-dom";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Theme from "../theme/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function MenuListComposition(props) {
  const classes = useStyles();
  const linkData = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleLogout = (event) => {
    console.log(linkData.logout);
    logout(() => {
      linkData.logout();
    });
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <ThemeProvider theme={Theme}>
      <div className={classes.root}>
        <div>
          <Grid container>
            <a
              className="user-btn"
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              {props.name}
              <Image
                src={require("../../../assets/images/account_circle.svg")}
                className="btn-pic"
              />
            </a>
          </Grid>
          <Popper
            className="popper"
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper
                  style={{
                    background: "#262626",
                    borderRadius: 0,
                    zIndex: 1000,
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem className="headerMenu">
                        <NavLink
                          to={{
                            pathname: "/app/savedPlayers",
                          }}
                          className="col-md-12 col-sm-12 col-xs-12"
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          Saved
                          {props.showBadge ? (
                            <Badge
                              badgeContent={""}
                              color="primary"
                              style={{ marginLeft: 12 }}
                              variant="dot"
                            ></Badge>
                          ) : (
                            <></>
                          )}
                          </NavLink>
                      </MenuItem>

                      <MenuItem className="headerMenu">
                        <NavLink
                          to={{
                            pathname:
                              "/app/login/" + localStorage.getItem("linkId"),
                          }}
                          onClick={handleLogout}
                          className="col-md-12 col-sm-12 col-xs-12"
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          Logout
                        </NavLink>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    </ThemeProvider>
  );
}
