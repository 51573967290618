import { db } from "../../util/firebase";

export const AddTeam = (team, callBack) => {
  db.collection("teams").add(team).then(callBack());
};

export const RemoveTeam = (t, callBack) => {
  const teamRef = db.collection("teams").doc(t.id);
  const refKey = db.collection("players");
  refKey.onSnapshot((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      const {
        name,
        position,
        bio,
        weight,
        height,
        caps,
        teamId,
        country,
        honors,
        ooc,
        age,
        status,
        profileImage,
        videoUrl,
        previousTeams,
      } = doc.data();

      const index = previousTeams.findIndex((pt) => pt.teamId === t.id);
      const player = {
        id: doc.id,
        name,
        position,
        bio,
        weight,
        height,
        caps,
        teamId,
        country,
        honors,
        ooc,
        age,
        status,
        profileImage,
        previousTeams,
        videoUrl,
      };
      if (teamId === t.id) {
        player.teamId = null;
      } else if (index > -1) {
        player.previousTeams.splice(index, 1);
      }
      const playerRef = db.collection("players").doc(doc.id);
      playerRef.update(player).then(() => {
        console.log("Player Updated");
      });
    });
  });
  teamRef.update({ deleted: true }).then(callBack());
};
