import React from 'react';
import { AuthConsumer } from '../context';

const guestComponent = (Component, FallbackComponent = null) => (
  <AuthConsumer>
    {({ linkGuest }) => (linkGuest() ? Component : FallbackComponent)}
  </AuthConsumer>
);

export default guestComponent;
