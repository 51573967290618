import React, { useState } from "react";
import { Form, Button, Image } from "react-bootstrap";
import Grid from '@material-ui/core/Grid';
import {login} from '../../../firebase/admin/login';
import UserInterface from '../../../interfaces/user';


function Login(props) {
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [password, setPassword] = useState("")

  const handleLogin = () =>{
    let user = Object.create(UserInterface);
    user = {
      email : email,
      password : password,
    }
    login(user, (error)=>{
      setError(error)
    });
  }


  return (
    <div className="container-main" style={{paddingRight:0}}>
      <Form autoComplete="off" className='login-form'>
      <Grid container>
        <Grid item>
        <Image
          width={170}
          src={require("../../../assets/images/rugby-logo.png")}
          alt=""
        />
        </Grid>
        <Grid item className="admin-area">
        <p className = 'text' >ADMIN AREA</p>
        </Grid>
      </Grid>
      <Grid container direction = 'column' spacing = {4} className="login-area">
        <Grid item>
        <p className = 'text loginHeading'>Please enter your username</p>
          <input
            onChange = {(e) => {setEmail(e.target.value)}}
            id = 'username'
            required
            type = 'email'
            className="textInput"
          />
        </Grid>
        <Grid item>
        <p className = 'text loginHeading'>Please enter your password</p>
          <input 
            required
            onChange = {(e) => {setPassword(e.target.value)}}
            type="password"
            className="textInput"
          />
          <p style = {{color:'red'}}>{error}</p>
        </Grid>
        <Grid item style={{padding:'13px 11px 0 15px'}}>
        <Button className = 'login-button' onClick = {handleLogin}>
          LOGIN
      </Button>
        </Grid>
      </Grid>
    </Form>
  </div>
  )
}

export default Login;