import React from 'react';
import { AuthConsumer } from '../context';

const guestComponent = (Component, FallbackComponent = null) => (
  <AuthConsumer>
    {({ adminGuest }) => (adminGuest() ? Component : FallbackComponent)}
  </AuthConsumer>
);

export default guestComponent;
