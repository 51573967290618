import { db } from "../../util/firebase";
import axios from "axios";

export const SendLink = (link, callBack) => {
  let InviteId;
  db.collection("links")
    .add(link)
    .then((documentReference) => {
      InviteId = documentReference.id;
      const inviteLink = "https://ymurugby.com/app/login/" + InviteId;
      const mailObj = {
        name: link.sentTo,
        email: link.emailAddress,
        inviteLink: inviteLink,
        password: link.password,
      };

      axios.post(
        "https://us-central1-ymu-rugby-site.cloudfunctions.net/sendInvite",
        mailObj,
        { headers: { "Content-Type": "application/json" } }
      );
      callBack();
    });
};

export const fetchData = (callBack) => {
  let analytics = [];
  const refKey = db.collection("activity");
  refKey.onSnapshot(async (querySnapshot) => {
    await querySnapshot.forEach((doc) => {
      analytics.push(doc.data());
    });
    callBack(analytics);
  });
};

export const fetchAnalytics = (email, lnk, dateSent, callBack) => {
  const refKey = db.collection("activity");
  let mostRecentActivity = dateSent;
  let saved = [];
  let downloads = [];
  let index = 0;
  refKey.onSnapshot((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      index += 1;
      const { type, link, player, date } = doc.data();
      if (lnk === link) {
        if (mostRecentActivity < date) {
          mostRecentActivity = date;
        }
        if (type === "SAVE_PLAYER") {
          saved.push(player);
        } else if (type === "DOWNLOAD_PLAYER_PROFILE") {
          downloads.push(player);
        }
      }
      if (index + 1 === querySnapshot.size) {
        callBack({
          id: lnk,
          emailAddress: email,
          savedPlayers: saved,
          CVDownloads: downloads,
          mostRecentActivity: mostRecentActivity,
        });
      }
    });
  });
};

export const updateAutoAlert = async (data, callback) => {
  data.forEach(async (obj) => {
    if (obj.action === "ADD") {
      await db.collection("autoAlert").add({
        key: obj.key,
        email: obj.email,
        notifyWhen: obj.notifyWhen,
      });
    } else if (obj.action === "UPDATE") {
      await db.collection("autoAlert").doc(obj.id).update({
        key: obj.key,
        email: obj.email,
        notifyWhen: obj.notifyWhen,
      });
    } else if (obj.action === "DELETE") {
      await db.collection("autoAlert").doc(obj.id).delete();
    }
  });
  callback();
};

export const fetchAutoAlertData = (callback) => {
  let data = [];
  const refKey = db.collection("autoAlert");
  refKey.onSnapshot((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    callback(data);
  });
};
