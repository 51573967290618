import React from "react";
import { Redirect, Route } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import protectedComponent from "./protected-component";
import SideBar from "../../components/admin/SideBar/sidebar";
import Header from "../../components/admin/Header/header";
import withWidth from "@material-ui/core/withWidth";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 960,
      lg: 1290,
      xl: 1920,
    },
  },
});

const spaceSide = {
  sm: 2,
  xs: 0,
  md: 2,
  lg: 2,
  xl: 2,
};

const spaceHeader = {
  sm: 10,
  xs: 12,
  md: 10,
  lg: 10,
  xl: 10,
};

function useWidth() {
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
}

function ProtectedRoute({ component: Component, ...rest }) {
  const width = useWidth();
  return (
    <Route
      {...rest}
      render={(props) =>
        protectedComponent(
          <ThemeProvider theme={theme}>
            {width === "xs" ? (
              <Grid container>
                <Grid
                  item
                  lg={spaceSide[width]}
                  xs={12}
                  sm={spaceSide[width]}
                  md={spaceSide[width]}
                >
                  <Grid container justify="space-between" direction="row">
                    <Grid item xs={4} sm={10}>
                      <SideBar width={width} />
                    </Grid>
                    <Grid item xs={8} sm={2}>
                      <Header />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  lg={spaceHeader[width]}
                  xs={spaceHeader[width]}
                  sm={spaceHeader[width]}
                  md={spaceHeader[width]}
                ></Grid>
                <Grid
                  item
                  lg={spaceHeader[width]}
                  xs={12}
                  sm={spaceHeader[width]}
                  md={spaceHeader[width]}
                >
                  <Component {...props} />
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid
                  item
                  lg={spaceSide[width]}
                  xs={spaceSide[width]}
                  sm={spaceSide[width]}
                  md={spaceSide[width]}
                >
                  <SideBar width={width} />
                </Grid>
                <Grid
                  item
                  lg={spaceHeader[width]}
                  xs={spaceHeader[width]}
                  sm={spaceHeader[width]}
                  md={spaceHeader[width]}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Header />
                    </Grid>
                    <Grid item>
                      <Component {...props} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </ThemeProvider>,
          <Redirect to="/admin/login" />
        )
      }
    />
  );
}
export default withWidth(theme)(ProtectedRoute);
