import React, { Component } from "react";
import { Image } from "react-bootstrap";
import ProfileMenu from "../Profile/menu";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Snackbar from "@material-ui/core/Snackbar";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import Paper from "@material-ui/core/Paper";
import PositionMenu from "./positionMenu";
import AuthContext from "../../../auth/context";
import { NavLink } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { fetchData, savePlayer } from "../../../firebase/app/players";
import SortMenu from "./sortMenu";
import DotLoader from "react-spinners/DotLoader";

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000",
      light: "#2B2B2B",
      dark: "#FFFFFF",
    },
    secondary: {
      main: "#FFC65B",
    },
    grey: {
      main: "black",
    },
    action: {
      main: "black",
      light: "black",
      dark: "black",
    },
  },
});

class PositionPlayers extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super();
    this.state = {
      linkId: localStorage.getItem("linkId"),
      players: [],
      filter: props.match.params.position,
      data: null,
      sort: false,
      loading: true,
      dataLoaded: false,
      openSnackbar: false,
      openSnackbar2: false,
      showBadge: false,
    };
  }

  async componentDidMount() {
    await fetchData(this.state.linkId, (data) => {
      this.setState({ data: data, dataLoaded: true, loading: false }, () => {
        this.handleSort("shuffle");
      });
    });
  }

  handleSort = (sort) => {
    let tempData = { ...this.state.data };
    if (sort === "shuffle") {
      tempData.playersList.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
    } else {
      tempData.playersList.sort((a, b) => new Date(a.ooc) - new Date(b.ooc));
    }
    this.setState({ data: tempData });
  };

  handleClose = () => {
    this.setState({ openSnackbar: false, openSnackbar2: false });
  };

  updateURLParameter = (url, param, paramVal) => {
    let baseURL = window.location.origin + "/app/positionPlayer/";
    console.log(baseURL);
    paramVal = paramVal.replace(/\s/g, "%20");
    let additionalURL = paramVal;
    return baseURL + additionalURL;
  };

  handleFilter = (filter) => {
    let list = [];
    if (this.state.data) {
      if (filter !== "filter") {
        this.state.data.playersList.forEach((player) => {
          if (player.position.includes(filter)) {
            list.push(player);
          }
        });
      }
    }
    window.history.replaceState(
      "",
      "",
      this.updateURLParameter(window.location.href, "position", filter)
    );
    this.setState({ filter: filter, players: list });
  };

  handleSavePlayer = (player) => {
    const data = { ...this.state.data };
    const index = data.savedPlayers.findIndex(
      (playerId) => playerId === player.id
    );

    if (index !== -1) {
      data.savedPlayers.splice(index, 1);
      this.setState({ data: data, openSnackbar2: true });
    } else {
      data.savedPlayers.push(player.id);
      this.setState({ data: data, openSnackbar: true });
    }
    savePlayer(player, this.state.linkId, () => {
      this.setState({ showBadge: true });
    });
  };

  render() {
    let listToRender;
    if (this.state.filter !== "filter") {
      let list = [];
      if (this.state.data) {
        this.state.data.playersList.forEach((pl) => {
          if (pl.position.includes(this.state.filter)) {
            list.push(pl);
          }
        });
      }
      listToRender = list;
    } else {
      if (this.state.data) {
        listToRender = this.state.data.playersList;
      }
    }

    return this.state.loading ? (
      <div className="parentDisable">
        <div className="overlay-box">
          <DotLoader size={150} color={"#FFC65B"} loading />
        </div>
      </div>
    ) : (
      <div className="public-app">
        <div className="background-kikcer">
          <div className="container-fluid p-0 ">
            <Grid container justify="space-between">
              <nav className="navbar navbar-expand-lg ">
                <NavLink
                  to={{
                    pathname: "/app/positions",
                  }}
                >
                  <Image
                    src={require("../../../assets/images/rugby-logo.png")}
                    width={170}
                  />
                </NavLink>
              </nav>
              <Paper className="profileMenu">
                <ProfileMenu
                  name={this.state.data ? this.state.data.sentTo : "name"}
                  showBadge={this.state.showBadge}
                />
              </Paper>
            </Grid>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.openSnackbar}
              onClose={this.handleClose}
              autoHideDuration={3000}
              message="Player Saved"
            />
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.openSnackbar2}
              onClose={this.handleClose}
              autoHideDuration={3000}
              message="Player Removed from Saved Players"
            />
            <div className="container main-sec">
              <div className="row m-0 top-row">
                <div style={{ display: "flex", width: "80%" }}>
                  <PositionMenu
                    filter={this.state.filter}
                    handleFilter={this.handleFilter}
                  />
                  <div style={{ marginLeft: "35px" }}>
                    <div style={{ padding: 0 }}>
                      <SortMenu handleSort={this.handleSort} />
                    </div>
                  </div>
                </div>
                <div className="results" style={{ width: "20%" }}>
                  <label>
                    {listToRender ? listToRender.length : 0} results
                  </label>
                </div>
              </div>
              <div className="row m-0 cards-row">
                {this.state.dataLoaded ? (
                  this.state.data ? (
                    listToRender.map((player, i) => {
                      return (
                        <div
                          key={i}
                          className="col-md-3 col-sm-6 col-xs-12 card"
                          style={{ width: "18rem", background: "transparent" }}
                        >
                          <NavLink
                            to={{
                              pathname: "/app/profile/" + player.id,
                            }}
                            // className="col-md-3 col-sm-6 col-xs-12"
                            style={{ color: "#FFC65B", textDecoration: "none" }}
                          >
                            <Image
                              className="card-img-top"
                              src={
                                player.profileImage
                                  ? player.profileImage
                                  : require("./../../../assets/images/dp-placeholder.png")
                              }
                              alt="Card image cap"
                            />
                            <div className="card-body">
                              <h5 className="card-title">{player.name}</h5>
                              <p className="card-text">{player.bio}</p>
                            </div>
                            <div className="card-body card-body-bottom p-0">
                              <span className="card-link">
                                {player.country && player.country.toUpperCase()}
                              </span>{" "}
                              -
                              <span className="card-link">
                                {player.team && player.team.toUpperCase()}
                              </span>
                            </div>
                          </NavLink>
                          <ThemeProvider theme={outerTheme}>
                            <div className="bottom-icon" title="Save Player">
                              <Checkbox
                                style={{ padding: 0 }}
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                                checkedIcon={<BookmarkIcon fontSize="small" />}
                                icon={
                                  <BookmarkBorderIcon
                                    fontSize="small"
                                    style={{ color: "#FFC65B" }}
                                  />
                                }
                                onClick={() => this.handleSavePlayer(player)}
                                checked={this.state.data.savedPlayers.some(
                                  (playerId) => playerId === player.id
                                )}
                              />
                            </div>
                          </ThemeProvider>
                        </div>
                      );
                    })
                  ) : (
                    <p>there are no players :(</p>
                  )
                ) : (
                  <p></p>
                )}
              </div>
              <div className="disclaimer">
                <h1>
                  Please Note: Approaches to players in contract are to be made
                  strictly in line with RFU Regulation 7.5 and World Rugby
                  Regulation 4.9
                </h1>
                <p>
                  This data shall be used solely by the club for internal
                  business purpose and destroyed. Sharing data with third
                  parties without prior written consent of YMU is not permitted.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PositionPlayers;
