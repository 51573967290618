import React, { Component } from "react";
import { Image } from "react-bootstrap";
import AuthContext from "../../../auth/context";
import { login } from "../../../firebase/app/login";
import ClipLoader from "react-spinners/ClipLoader";

class Login extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super();
    this.state = {
      linkId: props.match.params.id,
      password: "",
      loading: false,
      errorMessage: "",
    };
  }

  handleLogin = async () => {
    this.setState({ loading: true });
    const link = {
      id: this.state.linkId,
      password: this.state.password,
    };
    await login(link, (err) => {
      if (err !== "") {
        this.setState({ loading: false, errorMessage: err });
      } else {
        setTimeout(() => {
          this.setState({ loading: false });
          this.context.login();
        }, 2000);
      }
    });
  };

  render() {
    return (
      <div className="public-app">
        <div className="backgroundV">
          <video
            width="100%"
            autoPlay
            loop
            muted
            className="videoLogin"
            playsInline
            preload="auto"
          >
            <source
              src={require("../../../assets/images/background-video.mov")}
              type="video/mp4"
            />
          </video>
          <div className="container-fluid p-0 main d-flex justify-content-center align-items-center">
            <div className="row m-0">
              <div className="col-12 center-div">
                <div>
                  <Image
                    src={require("../../../assets/images/rugby-logo.png")}
                    width={170}
                  />
                </div>
                <label className="password-label">
                  Please enter your password
                </label>
                <input
                  type="password"
                  onChange={(e) => {
                    this.setState({
                      password: e.target.value,
                      errorMessage: "",
                    });
                  }}
                />
                <p
                  className="text"
                  style={{
                    color: "red",
                    margin: "10px 27px",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.errorMessage}
                </p>
                <div className="login-btn">
                  {this.state.loading ? (
                    <ClipLoader color="#FFC65B" size={30} />
                  ) : (
                    <button onClick={this.handleLogin}>Login</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
