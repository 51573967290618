import React from "react";
import { Navbar, Nav, Image } from "react-bootstrap";
import { NavLink, withRouter } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MenuIcon from "@material-ui/icons/Menu";

export const Navigation = (backTo) => {
  return (
    <Navbar className="sidebar">
      <Image
        src={require("../../../assets/images/rugby-logo.png")}
        className="logo-img"
      />
      <Nav style={{ marginTop: "30%", marginLeft: "5px" }}>
        <Nav.Item>
          <div style={{ paddingTop: 83 }}>
            <NavLink
              key={1}
              activeClassName="active"
              to="/admin/createlink"
              className="nav-link mr-3"
            >
              Create a link
            </NavLink>
          </div>
        </Nav.Item>
        <Nav.Item>
          <div style={{ paddingTop: 50 }}>
            <NavLink
              key={2}
              activeClassName="active"
              to="/admin/analytics"
              className="nav-link mr-3"
            >
              Analytics
            </NavLink>
          </div>
        </Nav.Item>
        <Nav.Item>
          <div style={{ paddingTop: 50 }}>
            <NavLink
              key={3}
              activeClassName="active"
              to="/admin/cms/1"
              className="nav-link mr-3"
            >
              CMS
            </NavLink>
          </div>
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};

function SideBar(props) {
  const [anchor, setAnchor] = React.useState(false);
  const toggleDrawer = () => (event) => {
    setAnchor(!anchor);
  };
  const backTo = props.match.params.backTo;
  const width = props.width;
  return width !== "xs" ? (
    <>{Navigation(backTo)}</>
  ) : (
    <>
      <MenuIcon
        onClick={toggleDrawer()}
        style={{ color: "white", padding: "10%" }}
      />
      <SwipeableDrawer
        anchor="left"
        open={anchor}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        <div
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          {Navigation(backTo)}
        </div>
      </SwipeableDrawer>
    </>
  );
}

export default withRouter(SideBar);
