import React, { Component } from "react";
import { Image } from "react-bootstrap";
import ProfileMenu from "../Profile/menu";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AuthContext from "../../../auth/context";
import { fetchData, getAgents } from "../../../firebase/app/players";
import { NavLink } from "react-router-dom";
import { positions } from "./positionData";

class PositionPlayers extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super();
    this.state = {
      linkId: localStorage.getItem("linkId"),
      data: null,
      agents: [],
    };
  }

  componentDidMount() {
    getAgents((list) => {
      this.setState({ agents: list });
    });
    fetchData(this.state.linkId, (data) => {
      this.setState({ data: data });
    });
  }

  render() {
    return (
      <div className="public-app">
        <div className="background-kikcer">
          <div className="container-fluid p-0 afterLogin">
            <Grid container justify="space-between">
              <nav className="navbar navbar-expand-lg ">
                <NavLink
                  to={{
                    pathname: "/app/positions",
                  }}
                >
                  <Image
                    src={require("../../../assets/images/rugby-logo.png")}
                    width={170}
                  />
                </NavLink>
              </nav>
              <Paper
                style={{
                  background: "#262626",
                  paddingLeft: 5,
                  borderRadius: 14,
                  position: "absolute",
                  right: "1.5%",
                  top: "2%",
                }}
              >
                <ProfileMenu
                  name={this.state.data ? this.state.data.sentTo : "name"}
                />
              </Paper>
            </Grid>
            <div className="main-sec-position">
              <div className="row m-0 top-row-center">
                <p>Select position to see available players</p>
                <div className="positions">
                  <Image
                    className="layout-svg"
                    src={require("../../../assets/images/pitch.svg")}
                    style={{ opacity: 0.26 }}
                  />
                  {positions.map((position, index) => {
                    return (
                      <NavLink
                        key={index}
                        to={{
                          pathname: "/app/positionPlayer/" + position.name,
                        }}
                      >
                        <div
                          className={"position" + position.id + " positionRM"}
                        >
                          <Image
                            className="shirt-img"
                            src={require("../../../assets/images/tshirt.svg")}
                          />
                          <p className="shirt-number">{position.id}</p>
                        </div>
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="agent-container">
            <p className="agentHeading">Agents</p>
            <Grid container direction="row">
              {this.state.agents.map((agent, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    lg={3}
                    style={{ padding: "15px" }}
                  >
                    <Grid
                      container
                      className="agent-sec"
                      alignItems="flex-start"
                      direction="column"
                    >
                      <p className="agent-name">{agent.name}</p>
                      <p className="agent-email">{agent.email}</p>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}
export default PositionPlayers;
