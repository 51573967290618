import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import DotLoader from "react-spinners/DotLoader";
import Theme from "../theme/theme";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import Btn from "@material-ui/core/Button";
import { ThemeProvider } from "@material-ui/core/styles";
import { AddTeam, RemoveTeam } from "../../../firebase/admin/teams";
import { db } from "../../../util/firebase";

class Teams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team: "",
      loading: false,
      teamList: [],
      dialog: null,
    };
  }

  addTeam = () => {
    const Today = new Date();
    const teamObj = {
      name: this.state.team,
      deleted: false,
      dateAdded: Today,
    };
    AddTeam(teamObj, () => {
      this.setState({ loading: false, team: "" });
    });
  };

  removeTeam = (team) => {
    if (window.confirm("Do you want to delete?")) {
      RemoveTeam(team, () => {
        this.setState({ loading: false, team: "" });
      });
    }
  };

  componentDidMount() {
    this.setState({ loading: true });
    const teamRef = db.collection("teams");

    teamRef.onSnapshot((querySnapshot) => {
      let teamList = [...this.state.teamList];

      querySnapshot.forEach((doc) => {
        const { name, deleted, dateAdded } = doc.data();
        if (!deleted) {
          teamList.push({
            id: doc.id,
            name,
            deleted,
            dateAdded,
          });
        }
      });

      teamList.sort((a, b) => (a.dateAdded < b.dateAdded ? 1 : -1));
      this.setState({ loading: false, teamList: teamList });
    });
    // Reset previously set state changed value.
    this.props.setStateChanged(false);
  }

  render() {
    return (
      <div style={{ paddingTop: "3%" }}>
        <ThemeProvider theme={Theme}>
          <div className={this.state.loading ? "parentDisable" : ""}>
            <div className="overlay-box">
              <DotLoader
                size={150}
                color="#FFC65B"
                loading={this.state.loading}
              />
            </div>
          </div>
          <Grid container spacing={4} direction="column">
            <Grid item sm={10} xs={10} md={4} lg={4} xl={4}>
              <p
                className="text pointsHeading"
                style={{ color: "#FFC65B", marginBottom: 0 }}
              >
                Add a team
              </p>
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
              md={4}
              lg={4}
              xl={4}
              style={{ paddingTop: 0 }}
            >
              <Paper className="full-input full-input-player">
                <label className="floating-label" htmlFor="name">
                  TEAM NAME
                </label>
                <input
                  className="input"
                  onChange={(e) => {
                    this.setState({ team: e.target.value });
                    this.props.setStateChanged(true);
                  }}
                  required
                  value={this.state.team}
                  type="text"
                  placeholder="Wolves"
                ></input>
              </Paper>
            </Grid>
            <Grid item sm={6} xs={12} md={4} lg={4} xl={4}>
              <Button className="ceateteam-btn" onClick={this.addTeam}>
                CREATE TEAM
              </Button>
            </Grid>
          </Grid>

          <p
            className="text pointsHeading"
            style={{ color: "#FFC65B", paddingTop: "10%" }}
          >
            Current teams on site
          </p>
          <TableContainer component={Paper}>
            <Table size="medium" aria-label="a dense table">
              <TableBody className="teamsTable">
                {this.state.teamList.map((team, index) => {
                  return (
                    <TableRow
                      key={index}
                      style={{ borderBottom: "0.5px solid white" }}
                    >
                      <TableCell component="th" scope="row">
                        {team.name}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "#EA2828", padding: 0 }}
                      >
                        <Btn
                          color="secondary"
                          className="removeBtn"
                          onClick={() => this.removeTeam(team)}
                        >
                          Remove
                        </Btn>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </ThemeProvider>
      </div>
    );
  }
}

export default Teams;
