import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import guestComponent from './guest-component';

function GuestRoute({ component: Component, ...rest }) {
  const currLinkId = (window.location.pathname.slice(11))
  const prevLinkId = localStorage.getItem('linkId')
  if(window.location.pathname.slice(0, 10) === "/app/login"){
    if(prevLinkId !== currLinkId){
      localStorage.setItem('linkId', currLinkId);
      localStorage.setItem('linkAuth', false);
    }
  }
    return(
      <Route
        {...rest}
        render={props =>
          guestComponent(<Component {...props} />, <Redirect to="/app/positions" />)
        }
      />
    )
}

export default GuestRoute;
