import React from "react";
import {signOutUser} from '../../../firebase/admin/login';
import {Grid, Button} from '@material-ui/core';


function Header(props) {
const logout = () =>{
    signOutUser(()=>{
        props.location.history.push('/login')
    })
}
  return (
      <div style = {{padding:10}}>
      <Grid container direction = 'row-reverse'>
          <Grid item >
              <Button className='logoutButton' style = {{color:'#FFFFFF'}} onClick = {logout}>Logout</Button>
          </Grid>
      </Grid>
      </div>
  );
}

export default Header;
