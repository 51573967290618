import React from "react";
import { Redirect, Route, Switch, Link } from "react-router-dom";
import AdminLogin from "../components/admin/Login/login";
import CMSHeader from "../components/admin/CMS/Header";
import CreateLink from "../components/admin/CreateLink/header";
import Analytics from "../components/admin/Analytics/header";
import EditDetails from "../components/admin/CMS/EditPlayerDetails";
import AdminProtectedRoute from "../auth/adminAuth/protected-route";
import AdminGuestRoute from "../auth/adminAuth/guest-route";

import LinkLogin from "../components/app/Login/login";
import AppGuestRoute from "../auth/appAuth/guest-route";
import AppProtectedRoute from "../auth/appAuth/protected-route";
import PositionPlayer from "../components/app/Position Players/positionPlayers";
import Positions from "../components/app/Positions/positions";
import SavedPlayers from "../components/app/Saved Players/savedPlayers";
import Profile from "../components/app/Profile/profile";

const NotFound = () => (
  <div>
    <h1 style={{ color: "#FFFFFF" }}>404 - Not Found!</h1>
    <Link to="/admin/cms/1">Go Home</Link>
  </div>
);

const Root = () => (
  <Switch>
    <Redirect exact from="/" to="/admin/createLink" />
    <AdminProtectedRoute path="/admin/cms/:selectedTab" component={CMSHeader} />
    <AdminProtectedRoute
      exact
      path="/admin/createLink"
      component={CreateLink}
    />
    <AdminProtectedRoute exact path="/admin/analytics" component={Analytics} />
    <AdminProtectedRoute
      exact
      path="/admin/editDetails"
      component={EditDetails}
    />
    <AdminGuestRoute exact path="/admin/login" component={AdminLogin} />
    <AppGuestRoute exact path="/app/login/:id" component={LinkLogin} />
    <AppProtectedRoute
      exact
      path="/app/positionPlayer/:position"
      component={PositionPlayer}
    />
    <AppProtectedRoute
      exact
      path="/app/savedPlayers"
      component={SavedPlayers}
    />
    <AppProtectedRoute exact path="/app/positions" component={Positions} />
    <AppProtectedRoute exact path="/app/profile/:player" component={Profile} />
    <Route component={NotFound} />
  </Switch>
);

export default Root;
