import React, { Component } from "react";
import "./App.css";
import Routes from "./routes/adminRoutes";
import DotLoader from "react-spinners/DotLoader";
import { AuthProvider } from "./auth/context";
import { auth } from "./util/firebase";

class App extends Component {
  constructor(props) {
    super(props);

    this.isAdminAuthenticated = this.isAdminAuthenticated.bind(this);
    this.Adminlogin = this.Adminlogin.bind(this);
    this.isAdminGuest = this.isAdminGuest.bind(this);
    this.isLinkAuthenticated = this.isLinkAuthenticated.bind(this);
    this.isLinkGuest = this.isLinkGuest.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.state = {
      loadingAuthState: true,
      auth: {
        user: null,
        adminCheck: this.isAdminAuthenticated,
        adminGuest: this.isAdminGuest,
        adminLogin: this.Adminlogin,
        linkCheck: this.isLinkAuthenticated,
        linkGuest: this.isLinkGuest,
        login: this.login,
        logout: this.logout,
      },
    };
  }

  isAdminAuthenticated() {
    return this.state.auth.user !== null;
  }

  isAdminGuest() {
    return !this.isAdminAuthenticated();
  }

  Adminlogin(email, password) {
    auth.signInWithEmailAndPassword(email, password);
    return;
  }

  isLinkAuthenticated() {
    // debugger
    // const currLinkId = (window.location.pathname.slice(12))
    // const prevLinkId = localStorage.getItem('linkId')
    // if(window.location.pathname.slice(0, 10) == "/app/login"){
    //   if(prevLinkId != currLinkId){
    //     debugger
    //     localStorage.setItem('linkId', currLinkId);
    //     localStorage.setItem('linkAuth', false);
    //   }
    // }
    return localStorage.getItem("linkAuth") === "true";
  }

  isLinkGuest() {
    return !this.isLinkAuthenticated();
  }

  login() {
    this.setState({
      ...auth,
      user: true,
    });
  }

  logout() {
    this.setState({
      ...auth,
      user: false,
    });
  }

  componentDidMount() {
    this.setState({ loadingAuthState: true }, () =>
      auth.onAuthStateChanged((user) => {
        const authObj = { ...this.state.auth, user };
        this.setState({
          loadingAuthState: false,
          auth: authObj,
        });
      })
    );
  }

  render() {
    return (
      <AuthProvider value={this.state.auth}>
        {this.state.loadingAuthState ? (
          <>
            <div className={this.state.loadingAuthState ? "parentDisable" : ""}>
              <div className="overlay-box">
                <DotLoader
                  size={150}
                  color="#FFC65B"
                  loading={this.state.loadingAuthState}
                />
              </div>
            </div>
          </>
        ) : (
          <Routes />
        )}
      </AuthProvider>
    );
  }
}

export default App;
