import firebase from "firebase/app";
import "firebase/auth"; 
import "firebase/storage";
import "firebase/firestore"; 

const firebaseConfig = {
  apiKey: "AIzaSyB6et0lLt5UPdcz86LAH6RDkSWw6Hl2QvY",
  authDomain: "ymu-rugby-site.firebaseapp.com",
  databaseURL: "https://ymu-rugby-site.firebaseio.com",
  projectId: "ymu-rugby-site",
  storageBucket: "ymu-rugby-site.appspot.com",
  messagingSenderId: "574924728550",
  appId: "1:574924728550:web:d995ba14ab9fbd68f2b327",
  measurementId: "G-RHHLV4900H",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const storage = firebase.storage();
export const db = firebase.firestore();
