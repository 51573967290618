import { auth } from "../../util/firebase";

export const login = (user, UserCallBack) => {
  let errorMessage = "hy";
  auth
    .signInWithEmailAndPassword(user.email, user.password)
    .catch(function (error) {
      errorMessage = error.message;
      UserCallBack(errorMessage);
    });
};

export const signOutUser = async () => {
  try {
    await auth.signOut();
  } catch (e) {
    console.log(e, "ERROR");
  }
};
