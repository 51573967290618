import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { Typography } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";
import { NavLink } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import Theme from "./../theme/checkTheme";

export default function DenseTable(props) {
  if (props.playersList) {
    props.playersList.sort((a, b) => (a.dateAdded < b.dateAdded ? 1 : -1));
  }
  return (
    <ThemeProvider theme={Theme}>
      <TableContainer component={Paper}>
        <Table size="medium" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className="table-editPlayer">Player Name</TableCell>
              <TableCell className="table-editPlayer" align="left">
                Team
              </TableCell>
              <TableCell align="right">
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <ExpandMoreIcon
                    style={{ color: "white", marginTop: "-4px" }}
                  />
                  <Typography className="table-editPlayer">Added</Typography>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.playersList
              ? props.playersList.map((player) => (
                  <TableRow key={player.id}>
                    <TableCell component="th" scope="row">
                      {player.name}
                    </TableCell>
                    <TableCell align="left">{player.team}</TableCell>
                    <TableCell align="right">
                      {player.dateAdded.toDate().getDate() +
                        "-" +
                        (player.dateAdded.toDate().getMonth() + 1) +
                        "-" +
                        player.dateAdded.toDate().getFullYear()}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ color: "#FFC65B", paddingRight: 0 }}
                    >
                      <NavLink
                        to={{
                          pathname: "/admin/editDetails",
                          state: { playerId: player.id },
                        }}
                        className="nav-link mr-3"
                        style={{ color: "#FFC65B", textDecoration: "none" }}
                      >
                        EDIT
                      </NavLink>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
}
