import React, {Component, Fragment} from "react";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Theme from '../theme/theme';
import * as classNames from 'classnames';
import DotLoader from "react-spinners/DotLoader";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {fetchAnalytics} from '../../../firebase/admin/links'
import DateRangeIcon from '@material-ui/icons/DateRange';
import IconButton from '@material-ui/core/IconButton';
import { ThemeProvider } from '@material-ui/core/styles';
import { db } from '../../../util/firebase'
import { fetchData } from "../../../firebase/admin/links";


class KPIS extends Component{
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      startDate: null,
      endDate:null,
      data:null,
      loading:true, 
      filteredData:[],
      linkEmails:[],
      analytics:null,
    };
  }

  async componentDidMount() {
    let links = []
    let emails = []
    let data = []
    let index = 0
    fetchData((analytics)=>{
      this.setState({analytics:analytics})
    })
    const refKey = db.collection('links');
    refKey.onSnapshot(async(querySnapshot) => {
      await querySnapshot.forEach(doc => {
        index += 1;
        emails.push(doc.data().emailAddress)
        links.push(doc.id)
        fetchAnalytics(doc.data().emailAddress, doc.id, doc.data().dateSent, (details)=>{
          data.push(details)
          if(index === querySnapshot.size){
            data.sort((a, b) => (a.mostRecentActivity < b.mostRecentActivity? 1 : -1))
            this.setState({linkEmails: emails, data:data})
          }
        })
      });
      this.setState({ loading:false})
    });
}

handleSearch = (e) =>{
  const data = this.state.startDate ? this.state.filteredData : this.state.data
  this.setState({loading:true, email:e.target.value})
  let list = []
  if(data){
    data.forEach(link => {
      if(link.emailAddress === e.target.value || link.emailAddress.toLowerCase().includes(e.target.value.toLowerCase())){
        list.push(link)
      }
    });
  }
    this.setState({loading:false, filteredData: list})
}

handleDateFilter = (picker) => {
  let list = []
  const data = this.state.email === ""? this.state.data : this.state.filteredData
 if(data){
  data.forEach((link, index) => {
    if(link.mostRecentActivity.toDate() > picker.startDate && link.mostRecentActivity.toDate() < picker.endDate){
      let saved = [];
      let downloads = [];
      this.state.analytics.forEach(activity=>{
        if(activity.link === link.id){
            if(activity.date.toDate() > picker.startDate && activity.date.toDate() < picker.endDate){
              if(activity.type === "SAVE_PLAYER"){
                saved.push(activity.player)
              }
              else if(activity.type === "DOWNLOAD_PLAYER_PROFILE"){
                downloads.push(activity.player)
              }
          }
        }
      })
      list.push({
        id:link.id,
        emailAddress:link.emailAddress,
        savedPlayers:saved,
        CVDownloads:downloads,
        mostRecentActivity:link.mostRecentActivity
      })
    }
    if(index+1 === data.length){
      this.setState({ filteredData: list})
    }
  });
 }
  this.setState({loading:false})
}

handleDateChange = (event, picker) => {
    this.setState({startDate: picker.startDate, endDate:picker.endDate, loading:true})
    this.handleDateFilter(picker)
  };


render(){
  const containerClasses = classNames(
    'daterangepicker-container'
  )
  let dataToRender;
  if(this.state.data){
    dataToRender = this.state.email === "" && !this.state.startDate? this.state.data : this.state.filteredData
  }
  return (
    <div style = {{paddingTop:'4%'}}>
    <ThemeProvider theme = {Theme}>
    <div className={this.state.loading ? 'parentDisable' : ''}>
        <div className = "overlay-box">
        <DotLoader 
          size={150}
          color={"#FFC65B"}
          loading={this.state.loading}
        />
        </div>
      </div>
        <Grid container spacing = {2} justify = 'flex-start' className="detailsROw">
          <Grid item sm = {4} xs = {12} md = {4} lg = {4} xl = {4}>
          <Paper className="full-input  borderRadius-left" style = {{backgroundColor:'rgb(255, 255, 255, 0.26)',padding:'2px 4px 2px 12px'}}>
            <label className="floating-label" htmlFor="name">
              ENTER USERS EMAIL
            </label>
            <input className = 'input'
              required
              onChange = {(e) => {this.handleSearch(e)}}
              type="text"
              placeholder="Type to search for specific user info"
            ></input>
          </Paper>
          </Grid>
          <Grid item sm = {4} xs = {12} md = {4} lg = {4} xl = {4}>
          <Paper className="full-input  borderRadius-right" style = {{backgroundColor:'rgb(255, 255, 255, 0.26)', boxShadow:'none',padding:'2px 4px 2px 12px'}}>
            <div className='borderLeft-Kpis'>
            <label className="floating-label" htmlFor="name">
              Date Range
            </label>
            <DateRangePicker
                timePicker
                initialSettings={{ startDate: new Date(), maxDate: new Date()}}
                drops='up'
                containerClass={containerClasses}
                onApply={this.handleDateChange}
                opens='center'>
                  <div className = 'date-range-picker'>
                  <span>
                    <p className = 'text' style = {{fontSize:12}}>
                    {this.state.startDate && this.state.endDate
                      ? this.state.startDate.format('MMM D, YYYY') + ' - ' + this.state.endDate.format('MMM D, YYYY'): "MMM/DD/YYYY"}
                      </p>
                  </span>
                  <IconButton className="KPIS-calender">
                    <DateRangeIcon />
                  </IconButton>
                  </div>
              </DateRangePicker>
            </div>
          </Paper>
          </Grid>
        </Grid> 
        <p className = 'text pointsHeading' style = {{color:'#FFC65B',paddingTop:'15px',marginBottom:0}}>Recent activity</p>
        <TableContainer component={Paper}>
      <Table className="table tabelKPIS" size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left" style = {{paddingLeft:0,color:'white'}}>User</TableCell>
            <TableCell align="left" style = {{paddingLeft:'7% !important',color:'white'}}>Saved players</TableCell>
            <TableCell align="left" style = {{color:'white'}}>CV Downloads</TableCell>
            {/* <TableCell align="center"></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.data && dataToRender.map((row) => (
            <Fragment>
            <TableRow>
            <TableCell  align="center" style = {{borderBottom:'1px solid #707070',verticalAlign: 'top',padding:'20px 0'}}>
                    <TableRow >
                    <TableCell style={{padding:'2px 0' }}>{row.emailAddress}</TableCell>
                    </TableRow>
                    <TableRow >
                    <TableCell style={{padding:'2px 0' }}>{row.mostRecentActivity.toDate().getMonth()+1 + '/' + row.mostRecentActivity.toDate().getDate()}</TableCell>
                    </TableRow>
                    <TableRow >
                    <TableCell style={{padding:'2px 0' }}>{row.mostRecentActivity.toDate().getHours() + ':' + row.mostRecentActivity.toDate().getMinutes()}</TableCell>
                    </TableRow>
              </TableCell>
            <TableCell align="center" style = {{borderBottom:'1px solid #707070',verticalAlign: 'top',padding:'20px 0'}}>
                {row.savedPlayers.map(player => (
                <TableRow>
                    <TableCell style={{padding:'2px 0' }}>{player}</TableCell>
                </TableRow>
                ))}
            </TableCell>
            <TableCell className="sesionCol" align="center" style = {{borderBottom:'1px solid #707070',verticalAlign: 'top',fontFamily:'Biotif-Regular'}}>
               {row.CVDownloads.map(player =>(
                 <TableRow>
                  <TableCell style={{padding:'2px 0' }}>{player}</TableCell>
                </TableRow>
               ))}
            </TableCell>
            <TableCell style = {{borderBottom:'1px solid #707070'}}>
                {/* <NavigateNextIcon fontSize = 'large' style = {{color:'white'}}/> */}
            </TableCell>
            </TableRow>
          </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </ThemeProvider>
    </div>
  )
}
}

export default (KPIS);