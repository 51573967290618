import React from "react";
import { Redirect, Route } from "react-router-dom";
import protectedComponent from "./protected-component";

function ProtectedRoute({ component: Component, ...rest }) {
  const linkId = localStorage.getItem("linkId");
  return (
    <Route
      {...rest}
      render={(props) =>
        protectedComponent(
          // Using key to remount the same component for profile.
          <Component key={props.location.key} {...props} />,
          <Redirect to={"/app/login/" + linkId} />
        )
      }
    />
  );
}
export default ProtectedRoute;
