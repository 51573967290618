import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import Modal from "react-modal";
import { Button } from "react-bootstrap";
import Theme from "../theme/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  fetchAutoAlertData,
  updateAutoAlert,
} from "../../../firebase/admin/links";
import DotLoader from "react-spinners/DotLoader";

class AutoAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{ key: 0, email: "", notifyWhen: "", action: "ADD" }],
      loading: false,
      openSnackbar: false,
      showModal: false,
    };
    Modal.setAppElement("#root");
  }

  handleNewEmailInputs = () => {
    let data = [...this.state.data];
    const keys = data.map((x) => x.key);
    const largestKey = Math.max(...keys);
    data.push({
      key: largestKey + 1,
      email: "",
      notifyWhen: "",
      action: "ADD",
    });
    this.setState({ data: data });
  };

  handleRemoveEmailInput = (key) => {
    let data = [...this.state.data];
    const index = data.findIndex((x) => x.key === key);
    if (index !== -1) {
      if (data[index].action === "ADD") {
        data = this.state.data.filter((x) => x.key !== key);
      } else {
        data[index].action = "DELETE";
      }
    }
    this.setState({ data: data });
  };

  emailInputHandler = (key, input) => {
    let data = [...this.state.data];
    const index = data.findIndex((x) => x.key === key);
    if (index !== -1) {
      data[index].email = input;
      if (data[index].action !== "DELETE" && data[index].action !== "ADD") {
        data[index].action = "UPDATE";
      }
    }
    this.setState({ data: data });
  };

  notifyWhenHandler = (key, input) => {
    let data = [...this.state.data];
    const index = data.findIndex((x) => x.key === key);
    if (index !== -1) {
      data[index].notifyWhen = input;
      if (data[index].action !== "DELETE" && data[index].action !== "ADD") {
        data[index].action = "UPDATE";
      }
    }
    this.setState({ data: data });
  };

  updateHandler = () => {
    let isValid = true;
    let data = [...this.state.data];

    data.forEach((element) => {
      if (!element.email || !element.notifyWhen) {
        isValid = false;
      }
    });

    if (!isValid) {
      this.setState({ openSnackbar: true });
    } else {
      updateAutoAlert(this.state.data, () => {
        this.loadData();
        this.setState({ showModal: true });
      });
    }
  };

  snackbarCloseHandler = () => {
    this.setState({ openSnackbar: false });
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  loadData = () => {
    fetchAutoAlertData((data) => {
      if (data.length > 0) {
        const d = data.map((x) => ({
          id: x.id,
          key: x.key,
          email: x.email,
          notifyWhen: x.notifyWhen,
          action: "NONE",
        }));
        this.setState({ data: d });
      } else {
        this.setState({
          data: [{ key: 0, email: "", notifyWhen: "", action: "ADD" }],
        });
      }
    });
  };

  componentDidMount = () => {
    this.loadData();
  };

  render() {
    return (
      <div style={{ paddingTop: "3%" }}>
        <div className={this.state.loading ? "parentDisable" : ""}>
          <div className="overlay-box">
            <DotLoader
              size={150}
              color={"#FFC65B"}
              loading={this.state.loading}
            />
          </div>
        </div>
        <div>
          <div className={this.state.showModal ? "modal-overlay" : ""}>
            <Modal
              className="modal-link"
              overlayClassName="mdl-overlay"
              isOpen={this.state.showModal}
              onRequestClose={this.onCloseModal}
            >
              <div className="modal-body">
                <p className="modal-text">Updated successfully!</p>
                <Button
                  className="modal-btn"
                  type="submit"
                  onClick={() => {
                    this.setState({ showModal: false });
                  }}
                >
                  DISMISS
                </Button>
              </div>
            </Modal>
          </div>
        </div>
        <ThemeProvider theme={Theme}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={this.state.openSnackbar}
            onClose={this.snackbarCloseHandler}
            autoHideDuration={3000}
            message="Inputs are invalid"
          />
          <Grid container spacing={2} direction="column">
            <Grid item>
              <p className="text alertHeading">
                Add email address that will be notified when CVs are downloaded
                and players are saved
              </p>
            </Grid>
            <Grid item>
              {this.state.data.map((input, index) => {
                return input.action !== "DELETE" ? (
                  <Grid
                    key={index}
                    container
                    spacing={2}
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid
                      item
                      sm={4}
                      xs={12}
                      md={4}
                      lg={4}
                      xl={4}
                      className="autoAlert"
                    >
                      <Paper
                        className="full-input borderRadius-left"
                        style={{
                          backgroundColor: "rgb(255, 255, 255, 0.26)",
                          padding: "2px 4px 2px 12px",
                        }}
                      >
                        <label className="floating-label" htmlFor="name">
                          Enter email address
                        </label>
                        <input
                          className="input"
                          required
                          value={input.email}
                          onChange={(e) => {
                            this.emailInputHandler(input.key, e.target.value);
                          }}
                          type="text"
                          placeholder="bradley.com@gmail.com"
                        ></input>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      sm={4}
                      xs={12}
                      md={4}
                      lg={4}
                      xl={4}
                      className="autoAlert"
                    >
                      <Paper
                        className="full-input borderRadius-right"
                        style={{
                          backgroundColor: "rgb(255, 255, 255, 0.26)",
                          boxShadow: "none",
                          padding: "2px 4px 2px 12px",
                        }}
                      >
                        <div className="borderLeft-div">
                          <label className="floating-label" htmlFor="name">
                            Notify When
                          </label>
                          <select
                            className="custom-select"
                            value={input.notifyWhen}
                            onChange={(e) => {
                              this.notifyWhenHandler(input.key, e.target.value);
                            }}
                          >
                            <option value="">Select</option>
                            <option value="ALL">All</option>
                            <option value="CV_DOWNLOAD">CV Download</option>
                            <option value="PLAYER_SAVED">Player Saved</option>
                          </select>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      className="crossPre"
                      sm={6}
                      xs={6}
                      md={4}
                      lg={4}
                      xl={4}
                    >
                      <div
                        className="crossPreAlert"
                        color="primary"
                        aria-label="add"
                        size="small"
                      >
                        <img
                          alt=""
                          src={require("../../../assets/images/add_circle.svg")}
                          className="remove-plus"
                          onClick={() => {
                            this.handleRemoveEmailInput(input.key);
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                ) : null;
              })}
            </Grid>
            <Grid item>
              <div
                className=""
                onClick={() => {
                  this.handleNewEmailInputs();
                }}
              >
                <img
                  src={require("../../../assets/images/add_circle.svg")}
                  alt=""
                  className="add-plus"
                />
              </div>
            </Grid>
            <Grid item className="addbtn-Grid">
              <Button className="add-button" onClick={this.updateHandler}>
                UPDATE
              </Button>
            </Grid>
          </Grid>
        </ThemeProvider>
      </div>
    );
  }
}

export default AutoAlert;
