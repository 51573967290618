import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { Image } from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    backgroundColor: "rgb(255, 255, 255, 0.26)",
    height: 120,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  input: {
    display: "none",
  },
});

class ImageUploadCard extends React.Component {
  state = {
    selectedFile: this.props.imageFile,
    fileName: "File Name",
  };

  componentDidMount() {
    if (this.props.videoFile == null) {
      this.setState({ selectedFile: null });
    }
  }

  handleUploadClick = (e) => {
    this.props.handleImageAsFile(e);
    const reader = new FileReader();
    reader.onloadend = function (e) {
      this.setState({
        selectedFile: [reader.result],
      });
    }.bind(this);
    this.setState({
      selectedFile: e.target.files[0],
      fileName: e.target.files[0].name,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Card className={classes.root}>
          {this.state.selectedFile || this.props.imageFile ? (
            <CardContent
              className="content"
              style={{ cursor: "pointer", padding: 0 }}
            >
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="column"
              >
                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file"
                  type="file"
                  onChange={this.handleUploadClick}
                />
                <label htmlFor="contained-button-file" className="imageUpload">
                  {this.state.selectedFile || this.props.imageFile ? (
                    <Image
                      src={
                        this.state.selectedFile
                          ? this.state.selectedFile
                          : this.props.imageFile
                      }
                      style={{
                        width: "100%",
                        objectFit: "cover",
                        height: "120px",
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </label>
              </Grid>
            </CardContent>
          ) : (
            <label htmlFor="contained-button-file" className="imageUpload">
              <CardContent className="content imageInnerDiv">
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                >
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                    onChange={this.handleUploadClick}
                  />

                  <Image src={require("./../../../assets/images/add.svg")} />

                  <Typography
                    variant="caption"
                    style={{ color: "#FFFFFF", fontFamily: "Biotif-regular" }}
                    display="block"
                    gutterBottom
                  >
                    Background Image
                  </Typography>
                </Grid>
              </CardContent>
            </label>
          )}
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ImageUploadCard);
