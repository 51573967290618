import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { ThemeProvider } from "@material-ui/core/styles";
import DotLoader from "react-spinners/DotLoader";
import BarLoader from "react-spinners/BarLoader";
import UploadImage from "./UploadImage";
import UploadVideo from "./UploadVideo";
import UploadBGImage from "./uploadBGImage";
import CountryOptions from "./countryOptions";
import { db } from "../../../util/firebase";
import {
  addPlayer,
  uploadVideoToDB,
  uploadBGImageToDb,
} from "../../../firebase/admin/players";
import theme from "../theme/checkTheme";

class AddPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      bio: "",
      weight: "",
      height: "",
      caps: "",
      age: "",
      honors: "",
      team: "",
      OOC: "",
      status: "",
      imageAsFile: null,
      videoAsFile: null,
      BGImage: null,
      country: null,
      selectedState: null,
      currentTeamAppearance: null,
      videoLoading: false,
      loading: true,
      exist: false,
      removePosition: false,
      changed: false,
      position: [],
      teams: [],
      positions: [],
      previousTeams: [],
      stats: [{ title: "", averageValue: 0, playerValue: 0 }],
      appearances: [{ year: "", value1: "", value2: "", value3: "" }],
      previousTeamsInputs: [{ id: 0, deleted: false }],
      multiplePositions: [{ id: 0, deleted: false }],
      positionDict: {},
      previousTeamDict: {},
    };
    this.handleImageAsFile = this.handleImageAsFile.bind(this);
    this.handleVideoAsFile = this.handleVideoAsFile.bind(this);
  }

  async componentDidMount() {
    let positions = [];
    let teams = [];
    let exist = false;

    const playersSnapshot = await db.collection("players").get();
    playersSnapshot.docs.forEach((doc) => {
      if (
        doc.data().name === this.state.name &&
        doc.data().position === this.state.position
      ) {
        exist = true;
      }
    });

    const positionsSnapshot = await db.collection("positions").get();
    positionsSnapshot.docs.forEach((doc) => {
      positions.push(doc.data().name);
    });

    const teamsSnapshot = await db.collection("teams").get();
    teamsSnapshot.docs.forEach((doc) => {
      if (!doc.data().deleted) {
        teams.push({ id: doc.id, name: doc.data().name });
      }
    });

    teams.sort((a, b) => (a.name > b.name ? 1 : -1));

    this.setState({
      loading: false,
      exist: exist,
      teams: teams,
      positions: positions,
    });
  }

  getYears = () => {
    const currentYear = parseInt(
      new Date().getFullYear().toString().substr(-2)
    );
    return [
      `${currentYear - 1}/${currentYear}`,
      `${currentYear - 2}/${currentYear - 1}`,
      `${currentYear - 3}/${currentYear - 2}`,
    ];
  };

  handleImageAsFile = (e) => {
    e.preventDefault();
    this.setState({ imageAsFile: e.target.files[0] });
  };

  handleBGImageAsFile = (e) => {
    e.preventDefault();
    this.setState({ BGImage: e.target.files[0], loading: true });

    uploadBGImageToDb(e.target.files[0], (BGImageUrl) => {
      this.setState({ BGImage: BGImageUrl, loading: false });
    });
  };

  handleVideoAsFile = (e) => {
    e.preventDefault();
    this.setState({ videoAsFile: e.target.files[0], videoLoading: true });

    uploadVideoToDB(e.target.files[0], (videoUrl) => {
      this.setState({ videoAsFile: videoUrl, videoLoading: false });
    });
  };

  handleStatusChange = (e) => {
    if (e.target.value === "active") {
      this.setState({ status: true });
    } else if (e.target.value === "inactive") {
      this.setState({ status: false });
    } else {
      this.setState({ status: null });
    }
  };

  addAnotherPosition = () => {
    let key = this.state.multiplePositions.length;
    let multiplePositions = [...this.state.multiplePositions];

    multiplePositions.push({
      id: key,
      deleted: false,
    });

    this.setState({ loading: false, multiplePositions: multiplePositions });
  };

  handlePositions = (e, id) => {
    this.setState({ changed: true });
    let exist = false;
    let positionDict = { ...this.state.positionDict };

    for (let key in positionDict) {
      if (positionDict[key] === e.target.value) {
        exist = true;
      }
    }
    if (!exist) {
      positionDict[id] = e.target.value;
      this.setState({ positionDict: positionDict });
    } else {
      // alert("The position already been assigned to the player")
    }
  };

  handleRemovePosition = (e, id) => {
    let positionChosen = this.state.positionDict.hasOwnProperty(id);
    let positionDict = { ...this.state.positionDict };
    let multiplePositions = [...this.state.multiplePositions];

    if (positionChosen) {
      positionDict[id] = null;
    }
    multiplePositions[id].deleted = true;

    this.setState({
      loading: false,
      positionDict: positionDict,
      multiplePositions: multiplePositions,
    });
  };

  addAnotherTeam = () => {
    let previousTeamsInputs = [...this.state.previousTeamsInputs];
    let key = previousTeamsInputs.length;
    previousTeamsInputs.push({
      id: key,
      deleted: false,
    });

    this.setState({ previousTeamsInputs: previousTeamsInputs });
  };

  handlePreviousTeams = (e, id) => {
    let exist = false;
    let currentTeam = false;
    let previousTeamDict = { ...this.state.previousTeamDict };

    this.setState({ changed: false });

    for (let key in previousTeamDict) {
      if (previousTeamDict[key] === e.target.value) {
        exist = true;
      }
      if (this.state.team === e.target.value) {
        currentTeam = true;
      }
    }

    if (!exist && !currentTeam) {
      if (previousTeamDict[id]) {
        previousTeamDict[id] = {
          team: e.target.value,
          appearance: previousTeamDict[id].appearance,
        };

        this.setState({ previousTeamDict: previousTeamDict });
      } else {
        previousTeamDict[id] = {
          team: e.target.value,
          appearance: 0,
        };

        this.setState({ previousTeamDict: previousTeamDict });
      }
    } else if (currentTeam) {
      //alert("This is the current team of the player")
    } else {
      // alert("The team already been added to the player history")
    }
  };

  handleTeamAppearance = (e, id) => {
    let previousTeamDict = { ...this.state.previousTeamDict };

    if (previousTeamDict[id]) {
      previousTeamDict[id] = {
        team: this.state.previousTeamDict[id].team,
        appearance: e.target.value,
      };

      this.setState({ previousTeamDict: previousTeamDict });
    } else {
      previousTeamDict[id] = {
        team: "",
        appearance: e.target.value,
      };

      this.setState({ previousTeamDict: previousTeamDict });
    }
  };

  handleRemoveTeam = (e, id) => {
    let previousTeamDict = { ...this.state.previousTeamDict };
    let previousTeamsInputs = [...this.state.previousTeamsInputs];
    let teamChosen = this.state.previousTeamDict.hasOwnProperty(id);

    if (teamChosen) {
      previousTeamDict[id] = null;
    }
    previousTeamsInputs[id].deleted = true;

    this.setState({
      previousTeamDict: previousTeamDict,
      previousTeamsInputs: previousTeamsInputs,
    });
  };

  handleAppearanceChange = (index, updatedAppearance) => {
    const appearancesCopy = [...this.state.appearances];
    appearancesCopy[index] = updatedAppearance;
    this.setState({ appearances: appearancesCopy });
  };

  addAnotherAppearance = () => {
    let previousAppearances = [...this.state.appearances];
    previousAppearances.push({
      year: "",
      value1: "",
      value2: "",
      value3: "",
    });
    this.setState({ appearances: previousAppearances });
  };

  removeAppearance = (index) => {
    const appearancesCopy = [...this.state.appearances];
    appearancesCopy.splice(index, 1);
    this.setState({ appearances: appearancesCopy });
  };

  handleStatChange = (index, updatedStat) => {
    const statsCopy = [...this.state.stats];
    statsCopy[index] = updatedStat;
    this.setState({ stats: statsCopy });
  };

  addAnotherStat = () => {
    let previousStats = [...this.state.stats];
    previousStats.push({
      title: "",
      averageValue: 0,
      playerValue: 0,
    });
    this.setState({ stats: previousStats });
  };

  removeStat = (index) => {
    const statsCopy = [...this.state.stats];
    statsCopy.splice(index, 1);
    this.setState({ stats: statsCopy });
  };

  handleAddPlayer = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    let position = [...this.state.position];
    let previousTeams = [...this.state.previousTeams];

    for (let key in this.state.positionDict) {
      if (this.state.positionDict[key]) {
        position.push(this.state.positionDict[key]);
      }
    }

    for (let key in this.state.previousTeamDict) {
      if (this.state.previousTeamDict[key]) {
        previousTeams.push(this.state.previousTeamDict[key]);
      }
    }

    const player = {
      name: this.state.name,
      position: position,
      bio: this.state.bio,
      weight: this.state.weight,
      height: this.state.height,
      caps: this.state.caps,
      teamId: this.state.team,
      currentTeamAppearance: this.state.currentTeamAppearance,
      country: this.state.country,
      honors: this.state.honors,
      ooc: this.state.OOC,
      age: this.state.age,
      status: this.state.status,
      statistics: this.state.stats.filter(
        (stat) => stat.title && stat.averageValue && stat.playerValue
      ),
      appearances: this.state.appearances.filter((obj) => !!obj.year),
      previousTeams: previousTeams.map((pTeam) => ({
        teamId: pTeam.team,
        appearance: pTeam.appearance,
      })),
      profileImage: this.state.imageAsFile,
      videoUrl: this.state.videoAsFile,
      BGImageUrl: this.state.BGImage,
      dateAdded: new Date(),
      deleted: false,
      hidden: false,
    };

    if (this.state.exist === true) {
      this.setState({
        loading: false,
        position: position,
        previousTeams: previousTeams,
      });
    } else {
      addPlayer(player, () => {
        this.setState({
          loading: false,
          position: position,
          previousTeams: previousTeams,
        });
      });
    }
  };

  render() {
    const {
      loading,
      videoLoading,
      multiplePositions,
      stats,
      appearances,
      weight,
      height,
      caps,
      age,
      status,
      previousTeamsInputs,
      teams,
      OOC,
      honors,
      positions,
      name,
      bio,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <div className={loading ? "parentDisable" : ""}>
          <div className="overlay-box">
            <DotLoader size={150} color="#FFC65B" loading={loading} />
          </div>
        </div>
        <div className={videoLoading ? "parentDisable" : ""}>
          <div className="overlay-box">
            <p className="text" style={videoLoading ? {} : { display: "none" }}>
              Uploading Video..
            </p>
            <BarLoader size={150} color="#FFC65B" loading={videoLoading} />
          </div>
        </div>
        <form
          autoComplete="off"
          style={{ padding: "10px 0" }}
          onSubmit={this.handleAddPlayer}
        >
          <Grid container direction="column">
            <Grid item>
              <p
                className="text pointsHeading"
                style={{ color: "#FFC65B", paddingTop: "15px" }}
              >
                Main Info
              </p>
            </Grid>
            <Grid item sm={6} xs={12} md={4}>
              <Paper className="full-input full-input-player">
                <label className="floating-label" htmlFor="name">
                  PLAYER NAME
                </label>
                <input
                  className="input"
                  required
                  value={name}
                  onChange={(e) => {
                    this.setState({ name: e.target.value, changed: true });
                  }}
                  type="text"
                  placeholder="John Doe"
                ></input>
              </Paper>
            </Grid>
            <Grid item>
              <p className="text pointsHeading" style={{ color: "#FFC65B" }}>
                Positions
              </p>
            </Grid>
            {multiplePositions.map((input, index) => {
              return input.deleted ? (
                <></>
              ) : (
                <Grid key={index} item xs={12} md={8}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item sm={6} xs={12} md={6}>
                      <Paper className="full-input full-input-position">
                        <label className="floating-label">POSITION</label>
                        <select
                          required
                          className="custom-select"
                          onChange={(e) => {
                            this.handlePositions(e, input.id);
                          }}
                        >
                          <option value={null}>Select</option>
                          {positions.map((position, i) => (
                            <option key={i} value={position}>
                              {position}
                            </option>
                          ))}
                        </select>
                      </Paper>
                    </Grid>
                    {index + 1 === multiplePositions.length ? (
                      <Grid item sm={6} xs={12} md={6}>
                        <Grid
                          container
                          direction="row"
                          spacing={2}
                          alignItems="center"
                        >
                          <Grid item>
                            <div
                              color="primary"
                              aria-label="add"
                              size="small"
                              onClick={this.addAnotherPosition}
                            >
                              <img
                                alt=""
                                src={require("../../../assets/images/add_circle.svg")}
                                className="add-plus"
                              />
                            </div>
                          </Grid>
                          <Grid item>
                            <p
                              className="text"
                              style={{ color: "#FFFFFF", fontSize: "14px" }}
                            >
                              Add another position
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item className="crossPre" xs={10} md={6}>
                        <div
                          color="primary"
                          aria-label="add"
                          size="small"
                          onClick={(e) =>
                            this.handleRemovePosition(e, input.id)
                          }
                        >
                          <img
                            alt=""
                            src={require("../../../assets/images/add_circle.svg")}
                            className="remove-plus"
                          />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12} md={10} lg={8}>
              <Paper
                className="full-input-bio"
                style={{
                  backgroundColor: "rgb(255, 255, 255, 0.26)",
                  margin: " 5px 0px 40px",
                }}
              >
                <label className="floating-label">BIO</label>
                <textarea
                  className="input"
                  required
                  value={bio}
                  onChange={(e) => {
                    this.setState({ bio: e.target.value, changed: true });
                  }}
                  type="text"
                  placeholder="Brief Description of the player"
                ></textarea>
              </Paper>
            </Grid>
            <Grid item>
              <p
                className="text pointsHeading"
                style={{ color: "#FFC65B", marginBottom: 0 }}
              >
                Content
              </p>
            </Grid>
            <Grid item>
              <Grid container spacing={4}>
                <Grid item sm={3} xs={6} md={2} className="addPlayer">
                  <UploadImage
                    type="profile"
                    handleImageAsFile={this.handleImageAsFile}
                  />
                </Grid>
                <Grid item xs={6} md={3} className="videoPlayer">
                  <UploadVideo handleVideoAsFile={this.handleVideoAsFile} />
                </Grid>
                <Grid item xs={6} md={3} className="addPlayer">
                  <UploadBGImage
                    type="BGImage"
                    handleImageAsFile={this.handleBGImageAsFile}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <p
                className="text pointsHeading"
                style={{ color: "#FFC65B", marginBottom: 0 }}
              >
                Stats
              </p>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={4} md={2}>
                  <Paper className="full-input-select borderRadius-left statSpacing">
                    <label className="floating-label stats-label">WEIGHT</label>
                    <input
                      className="inputS"
                      min="0"
                      placeholder="60.3"
                      value={weight}
                      type="number"
                      step=".1"
                      onChange={(e) => {
                        this.setState({
                          weight: e.target.value,
                          changed: true,
                        });
                      }}
                    ></input>
                    <span className="unit-span">kg</span>
                  </Paper>
                </Grid>
                <Grid item xs={4} md={2}>
                  <Paper className="full-input-select statSpacing">
                    <label className="floating-label stats-label">HEIGHT</label>
                    <input
                      className="inputS"
                      value={height}
                      placeholder="580"
                      type="number"
                      min="0"
                      onChange={(e) => {
                        this.setState({
                          height: e.target.value,
                          changed: true,
                        });
                      }}
                    ></input>
                    <span className="unit-span">cm</span>
                  </Paper>
                </Grid>
                <Grid item xs={4} md={2}>
                  <Paper className="full-input-select borderRadius-rightM statSpacing">
                    <label className="floating-label stats-label ">
                      COUNTRY
                    </label>
                    <select
                      className="custom-select"
                      onChange={(e) => {
                        this.setState({
                          country: e.target.value,
                          changed: true,
                        });
                      }}
                    >
                      <CountryOptions />
                    </select>
                  </Paper>
                </Grid>
                <Grid item xs={4} md={2}>
                  <Paper className="full-input-select borderRadius-leftM statSpacing">
                    <label className="floating-label stats-label">CAPS</label>
                    <input
                      className="input"
                      value={caps}
                      placeholder="7"
                      type="number"
                      min="0"
                      onChange={(e) => {
                        this.setState({ caps: e.target.value, changed: true });
                      }}
                    ></input>
                  </Paper>
                </Grid>
                <Grid item xs={4} md={2}>
                  <Paper className="full-input-select statSpacing">
                    <label className="floating-label stats-label">DOB</label>
                    <input
                      type="date"
                      className="input"
                      value={age}
                      onChange={(e) => {
                        this.setState({ age: e.target.value, changed: true });
                      }}
                    ></input>
                  </Paper>
                </Grid>
                <Grid item xs={4} md={2}>
                  <Paper className="full-input-select borderRadius-right borderRadius-rightM statSpacing">
                    <label className="floating-label stats-label">STATUS</label>
                    <input
                      className="input"
                      placeholder="Active"
                      value={status}
                      type="text"
                      onChange={(e) => {
                        this.setState({ status: e.target.value });
                      }}
                    ></input>
                  </Paper>
                </Grid>
                <Grid item xs={4} md={2} style={{ padding: "0 8px" }}>
                  <Paper
                    className="full-input-select borderRadius-left borderRadius-leftM "
                    style={{
                      backgroundColor: "rgb(255, 255, 255, 0.26)",
                      margin: " 0px 0 50px 0",
                    }}
                  >
                    <label className="floating-label stats-label">
                      HONOURS
                    </label>
                    <input
                      className="input"
                      placeholder="England U20’s"
                      value={honors}
                      type="text"
                      onChange={(e) => {
                        this.setState({
                          honors: e.target.value,
                          changed: true,
                        });
                      }}
                    ></input>
                  </Paper>
                </Grid>
                <Grid item xs={4} md={2} style={{ padding: "0 8px" }}>
                  <Paper
                    className="full-input-select borderRadius-right borderRadius-rightM"
                    style={{
                      backgroundColor: "rgb(255, 255, 255, 0.26)",
                      margin: " 0px 0 50px 0",
                    }}
                  >
                    <label className="floating-label stats-label">OOC</label>
                    <input
                      type="date"
                      className="input"
                      value={OOC}
                      onChange={(e) => {
                        this.setState({ OOC: e.target.value, changed: true });
                      }}
                    ></input>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <p
                className="text pointsHeading"
                style={{ color: "#FFC65B", marginBottom: 0 }}
              >
                Appearances
              </p>
            </Grid>
            {appearances.map((appearance, i) => (
              <Grid key={i} item>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <label className="floating-label stats-label">Date</label>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Box
                      width={80}
                      borderRadius={5}
                      className="no-label-input full-input-appearance"
                    >
                      <select
                        value={appearance.year}
                        className="custom-select full-select"
                        onChange={(e) => {
                          this.handleAppearanceChange(i, {
                            ...appearance,
                            year: e.target.value,
                          });
                        }}
                      >
                        <option value="">Select</option>
                        {this.getYears().map((year, i) => (
                          <option key={i} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </Box>
                  </Grid>
                  {appearances.length === i + 1 && appearances.length < 3 ? (
                    <Grid item xs={8} md={10}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        onClick={this.addAnotherAppearance}
                      >
                        <img
                          alt=""
                          src={require("../../../assets/images/add_circle.svg")}
                          className="add-plus"
                        />
                        <Box fontSize="14px" color="#fff" marginLeft="10px">
                          Add another Date
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={8} md={10}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        onClick={(e) => this.removeAppearance(i)}
                      >
                        <img
                          alt=""
                          src={require("../../../assets/images/add_circle.svg")}
                          className="remove-plus"
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box
                      width={170}
                      borderRadius={5}
                      className="no-label-input full-input-appearance mt-0"
                    >
                      <input
                        value={appearance.value1}
                        className="input"
                        type="text"
                        placeholder="value 1"
                        onChange={(e) =>
                          this.handleAppearanceChange(i, {
                            ...appearance,
                            value1: e.target.value,
                          })
                        }
                      ></input>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      width={170}
                      borderRadius={5}
                      className="no-label-input full-input-appearance mt-0"
                    >
                      <input
                        className="input"
                        type="text"
                        placeholder="value 2"
                        value={appearance.value2}
                        onChange={(e) =>
                          this.handleAppearanceChange(i, {
                            ...appearance,
                            value2: e.target.value,
                          })
                        }
                      ></input>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      width={170}
                      borderRadius={5}
                      className="no-label-input full-input-appearance mt-0"
                    >
                      <input
                        className="input"
                        type="text"
                        placeholder="value 3"
                        value={appearance.value3}
                        onChange={(e) =>
                          this.handleAppearanceChange(i, {
                            ...appearance,
                            value3: e.target.value,
                          })
                        }
                      ></input>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid item>
              <p
                className="text pointsHeading"
                style={{ color: "#FFC65B", marginBottom: 0 }}
              >
                Graph
              </p>
            </Grid>
            {stats.map((stat, i) => (
              <Grid key={i} item>
                <Grid container alignItems="center">
                  <Grid item sm={3} xs={6} md={2}>
                    <label className="floating-label stats-label">
                      Statistic title
                    </label>
                    <Paper className="no-label-input full-input-appearance">
                      <input
                        value={stat.title}
                        className="input"
                        placeholder="Title"
                        type="text"
                        min="0"
                        onChange={(e) =>
                          this.handleStatChange(i, {
                            ...stat,
                            title: e.target.value,
                          })
                        }
                      ></input>
                    </Paper>
                  </Grid>
                  <Grid item sm={3} xs={6} md={2}>
                    <label className="floating-label stats-label">
                      Average value
                    </label>
                    <Paper className="no-label-input full-input-appearance">
                      <input
                        value={stat.averageValue}
                        className="input"
                        placeholder="0"
                        type="number"
                        min="0"
                        onChange={(e) =>
                          this.handleStatChange(i, {
                            ...stat,
                            averageValue: e.target.value,
                          })
                        }
                      ></input>
                    </Paper>
                  </Grid>
                  <Grid item sm={3} xs={6} md={2}>
                    <label className="floating-label stats-label">
                      Players value
                    </label>
                    <Paper className="no-label-input full-input-appearance">
                      <input
                        className="input"
                        placeholder="0"
                        type="number"
                        min="0"
                        value={stat.playerValue}
                        onChange={(e) =>
                          this.handleStatChange(i, {
                            ...stat,
                            playerValue: e.target.value,
                          })
                        }
                      ></input>
                    </Paper>
                  </Grid>
                  {stats.length === i + 1 && stats.length < 7 ? (
                    <Grid item sm={3} xs={6} md={6}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        onClick={this.addAnotherStat}
                      >
                        <img
                          alt=""
                          src={require("../../../assets/images/add_circle.svg")}
                          className="add-plus"
                        />
                        <Box fontSize="14px" color="#fff" marginLeft="10px">
                          Add another Statistic
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item sm={3} xs={6} md={6}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        onClick={(e) => this.removeStat(i)}
                      >
                        <img
                          alt=""
                          src={require("../../../assets/images/add_circle.svg")}
                          className="remove-plus"
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
            <Grid item>
              <p
                className="text pointsHeading"
                style={{ color: "#FFC65B", marginBottom: 0 }}
              >
                Teams
              </p>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item sm={6} xs={12} md={4}>
                  <Paper
                    className="full-input full-input-team"
                    style={{
                      backgroundColor: "rgb(255, 255, 255, 0.26)",
                      margin: " 15px 0 15px 0",
                    }}
                  >
                    <label className="floating-label" htmlFor="name">
                      TEAM
                    </label>
                    <select
                      className="custom-select"
                      onChange={(e) => {
                        this.setState({ team: e.target.value, changed: true });
                      }}
                    >
                      <option value="">Select</option>
                      {teams.map((team, i) => (
                        <option key={i} value={team.id}>
                          {team.name}
                        </option>
                      ))}
                    </select>
                  </Paper>
                </Grid>
                <Grid item sm={6} xs={12} md={2}>
                  <Paper className="full-input full-input-appearance">
                    <label className="floating-label stats-label">
                      APPEARANCES
                    </label>
                    <input
                      className="input"
                      style={{ width: "80%" }}
                      placeholder="7"
                      type="number"
                      min="0"
                      onChange={(e) => {
                        this.setState({
                          currentTeamAppearance: e.target.value,
                          changed: true,
                        });
                      }}
                    ></input>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            {previousTeamsInputs.map((input, index) => {
              return input.deleted ? (
                <></>
              ) : (
                <Grid key={index} item>
                  <Grid container alignItems="center">
                    <Grid item sm={6} xs={12} md={4}>
                      <Paper className="full-input full-input-Preteam">
                        <label className="floating-label">PREVIOUS TEAM</label>
                        <select
                          required
                          className="custom-select"
                          onChange={(e) =>
                            this.handlePreviousTeams(e, input.id)
                          }
                        >
                          <option value={null}>Select</option>
                          {teams.map((team, i) => (
                            <option key={i} value={team.id}>
                              {team.name}
                            </option>
                          ))}
                        </select>
                      </Paper>
                    </Grid>
                    <Grid item sm={6} xs={12} md={2}>
                      <Paper className="full-input full-input-appearance">
                        <label className="floating-label stats-label">
                          APPEARANCES
                        </label>
                        <input
                          className="input"
                          style={{ width: "80%" }}
                          placeholder="7"
                          type="number"
                          min="0"
                          onChange={(e) =>
                            this.handleTeamAppearance(e, input.id)
                          }
                        ></input>
                      </Paper>
                    </Grid>

                    {previousTeamsInputs.length === index + 1 ? (
                      <Grid item sm={6} xs={12} md={4}>
                        <Grid
                          container
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          style={{ paddingLeft: "30px" }}
                        >
                          <Grid item>
                            <div
                              color="primary"
                              aria-label="add"
                              size="small"
                              onClick={this.addAnotherTeam}
                            >
                              <img
                                alt=""
                                src={require("../../../assets/images/add_circle.svg")}
                                className="add-plus"
                              />
                            </div>
                          </Grid>
                          <Grid item>
                            <p
                              className="text"
                              style={{ color: "#FFFFFF", fontSize: "14px" }}
                            >
                              Add another Team
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item className="crossPre" xs={10} md={6}>
                        <div
                          color="primary"
                          aria-label="add"
                          size="small"
                          onClick={(e) => this.handleRemoveTeam(e, input.id)}
                        >
                          <img
                            alt=""
                            src={require("../../../assets/images/add_circle.svg")}
                            className="remove-plus"
                          />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              );
            })}
            <Grid item className="addbtn-Grid">
              <Button type="submit" className="add-button">
                ADD PLAYER
              </Button>
            </Grid>
          </Grid>
        </form>
      </ThemeProvider>
    );
  }
}

export default AddPlayer;
