import { createMuiTheme } from "@material-ui/core/styles";

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
      light: "#FFFFFF",
      dark: "#FFFFFF",
    },
    secondary: {
      main: "#EA2828",
    },
    danger: {
      main: "#EA2828",
    },
    grey: {
      main: "#FFFFFF",
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiLink: {
      root: {
        color: "#707070",
        display: "flex",
        padding: "20px",
        "&:hover": {
          color: "#FFFFFF",
        },
      },
    },
    MuiListItem: {
      root: {
        "&:hover": {
          textDecoration: "none",
          backgroundColor: "transparent",
        },
        paddingTop: "20px",
      },
    },
    MuiTab: {
      root: {
        color: "#FFFFFF",
        "&$selected": {
          backgroundColor: "transparent",
          borderBottomColor: "blue",
        },
      },
      textColorPrimary: {
        color: "#BCBCBC",
        "&$selected": {
          color: "#FFFFFF",
        },
        "&$disabled": {
          color: "#707070",
        },
      },
    },
    MuiTextField: {
      root: {
        borderRadius: "4px",
        backgroundColor: "#707070",
        color: "#FFFFFF",
      },
    },
    MuiTableContainer: {
      root: {
        backgroundColor: "#121212",
        border: "none",
      },
    },
    MuiTableCell: {
      root: {
        color: "#FFFFFF",
        borderBottom: "none",
      },
      head: {
        color: "#C2C2C2",
        fontWeight: "bold",
      },
      body: {
        color: "#FFFFFF",
        fontFamily: "Biotif",
      },
      alignRight: {
        textAlign: "right",
        flexDirection: "row-reverse",
      },
    },
    MuiTableHead: {
      root: {
        borderBottom: "0.5px solid white",
      },
    },
    MuiAccordion: {
      root: {
        backgroundColor: "transparent",
      },
    },
    MuiAccordionSummary: {
      root: {
        backgroundColor: "transparent",
        color: "#FFFFFF",
      },
    },
  },
});

export default outerTheme;
