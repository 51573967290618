import React, { Component } from "react";
import DotLoader from "react-spinners/DotLoader";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ThemeProvider } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import theme from "../theme/checkTheme";

const styles = {
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  expandIcon: {
    transform: "rotate(0deg)",
  },
  collapseIcon: {
    transform: "rotate(180deg)",
  },
};

class TeamAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team: this.props.team,
      expanded: true,
      loading: false,
      selectAll: true,
    };
  }

  checkBoxClicked = (e, player) => {
    this.props.handleCheckBoxClick(player);
  };

  handleSelectAll = () => {
    this.props.handleSelectUnselectAll(this.props.team.teamId);
  };

  render() {
    const classes = this.props.classes;
    const { loading, expanded, team } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <div className={loading ? "parentDisable" : ""}>
          <div className="overlay-box">
            <DotLoader size={150} color="#FFC65B" loading={loading} />
          </div>
        </div>
        <div className={classes.root}>
          <Accordion>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className="teamRow"
            >
              <Grid item>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  onClick={(e) => {
                    this.setState({ expanded: !expanded });
                  }}
                >
                  <Typography className="accText-creatlink" variant="body2">
                    {this.props.team.teamName}
                  </Typography>
                  <ExpandMoreIcon
                    className={
                      expanded ? classes.expandIcon : classes.collapseIcon
                    }
                  />
                </AccordionSummary>
              </Grid>
              <Grid item className="selectAll-row">
                <Grid container direction="row">
                  <Grid item className="checkBox-align">
                    <Checkbox
                      className="checkbox-select"
                      icon={
                        <FiberManualRecordIcon style={{ color: "white" }} />
                      }
                      checkedIcon={<CheckCircleIcon />}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      onChange={this.handleSelectAll}
                      checked={this.props.team.allSelected}
                    />
                  </Grid>
                  <Grid item>
                    <p className="text selectHeading">Select/Unselect all</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <AccordionDetails>
              <Grid container>
                {team.players.length === 0 ? (
                  <p className="accText">
                    There are no players registered in this team
                  </p>
                ) : (
                  team.players.map((player, index) => {
                    return (
                      <Box
                        key={index}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Checkbox
                          icon={
                            <CheckBoxOutlineBlankIcon
                              style={{ color: "white" }}
                            />
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                          onChange={(e) => this.checkBoxClicked(e, player)}
                          checked={player.checked}
                        />
                        <p className="text teamPlayers">{player.name}</p>
                      </Box>
                    );
                  })
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      </ThemeProvider>
    );
  }
}

TeamAccordion.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TeamAccordion);
