import React, { Component } from "react";
import { Image } from "react-bootstrap";
import ProfileMenu from "../Profile/menu";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import AuthContext from "../../../auth/context";
import { db } from "../../../util/firebase";
import {
  fetchData,
  savePlayer,
  fetchSavedPlayers,
  downloadCV,
} from "../../../firebase/app/players";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfDocument } from "../Profile/pdfDownload";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import DotLoader from "react-spinners/DotLoader";

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000",
      light: "#2B2B2B",
      dark: "#FFFFFF",
    },
    secondary: {
      main: "#FFC65B",
    },
    grey: {
      main: "black",
    },
    action: {
      main: "black",
      light: "black",
      dark: "black",
    },
  },
});

class SavedPlayers extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super();
    this.state = {
      linkId: localStorage.getItem("linkId"),
      players: [],
      teams: [],
      data: null,
      dataLoaded: false,
      loading: true,
      saveLoading: false,
      savedPlayers: [],
    };
  }

  async componentDidMount() {
    let teams = [];
    const teamsSnapshot = await db.collection("teams").get();
    teamsSnapshot.docs.forEach((doc) => {
      if (!doc.data().deleted) {
        teams.push({ id: doc.id, name: doc.data().name });
      }
    });
    this.setState({ teams: teams });

    await fetchData(this.state.linkId, (data) => {
      this.setState({ data: data, dataLoaded: true });
    });

    await fetchSavedPlayers(this.state.linkId, (savedPlayers) => {
      this.setState({ savedPlayers: savedPlayers, loading: false });
    });
  }

  handleCVDownload = (player) => {
    downloadCV(player, this.state.linkId, () => {});
  };

  handleSavePlayer = (player) => {
    this.setState({ saveLoading: true });
    const index = this.state.savedPlayers.findIndex((p) => p.id === player.id);
    if (index !== -1) {
      this.state.savedPlayers.splice(index, 1);
    } else {
      this.state.savedPlayers.push(player);
    }
    savePlayer(player, this.state.linkId, () => {
      this.setState({ saveLoading: false });
    });
  };

  render() {
    return this.state.loading ? (
      <div className="parentDisable">
        <div className="overlay-box">
          <DotLoader size={150} color="#FFC65B" />
        </div>
      </div>
    ) : (
      <div className="public-app">
        <div className={this.state.saveLoading ? "parentDisable" : ""}>
          <div className="overlay-box">
            <DotLoader
              size={150}
              color={"#FFC65B"}
              loading={this.state.saveLoading}
            />
          </div>
        </div>
        <div className="background-kikcer">
          <div className="container-fluid p-0 ">
            <Grid container justify="space-between">
              <nav className="navbar navbar-expand-lg ">
                <NavLink
                  to={{
                    pathname: "/app/positions",
                  }}
                >
                  <Image
                    src={require("../../../assets/images/rugby-logo.png")}
                    width={170}
                  />
                </NavLink>
              </nav>
              <Paper
                style={{
                  background: "#262626",
                  paddingLeft: 5,
                  borderRadius: 14,
                  position: "absolute",
                  right: "1.5%",
                  top: "2%",
                }}
              >
                <ProfileMenu
                  name={this.state.data ? this.state.data.sentTo : "name"}
                />
              </Paper>
            </Grid>
            <div className="container main-sec-savedPlayers">
              <div className="row m-0 top-row">
                <div className="col-md-4 col-xs-4 SavedPlayers-heading">
                  <p>My Saved players</p>
                </div>
                <div className="col-md-2 offset-md-6 results">
                  <label>
                    {this.state.data ? this.state.savedPlayers.length : 0}{" "}
                    results
                  </label>
                </div>
              </div>
              <div className="row m-0 cards-row">
                {this.state.dataLoaded ? (
                  this.state.data ? (
                    this.state.savedPlayers.map((player, index) => {
                      return (
                        <div
                          key={index}
                          className="col-md-3 col-sm-6 col-xs-12 card"
                          style={{ width: "18rem", background: "transparent" }}
                        >
                          <NavLink
                            to={{
                              pathname: "/app/profile/" + player.id,
                            }}
                            // class="col-md-3 col-sm-6 col-xs-12"
                            style={{ color: "#FFC65B", textDecoration: "none" }}
                          >
                            <Image
                              className="card-img-top"
                              src={
                                player.profileImage
                                  ? player.profileImage
                                  : require("./../../../assets/images/dp-placeholder.png")
                              }
                              alt="Card image cap"
                            />
                            <div className="card-body pl-0">
                              <h5 className="card-title">{player.name}</h5>
                              <p className="card-text">{player.bio}</p>
                            </div>
                            <div className="card-body card-body-bottom p-0">
                              <span className="card-link">
                                {player.country && player.country.toUpperCase()}
                              </span>{" "}
                              -
                              <span className="card-link">
                                {player.team && player.team.toUpperCase()}
                              </span>
                            </div>
                            {/* <p class="text-overcard">RECOMENDED</p> */}
                          </NavLink>
                          <div
                            onClick={() => this.handleCVDownload(player)}
                            title="Download PDF"
                          >
                            {this.state.teams.length > 0 ? (
                              <PDFDownloadLink
                                document={
                                  <PdfDocument
                                    teams={this.state.teams}
                                    player={player}
                                    name={
                                      this.state.data
                                        ? this.state.data.sentTo
                                        : "name"
                                    }
                                  />
                                }
                                fileName={player.name + " Info.pdf"}
                              >
                                <Image
                                  src={require("../../../assets/images/document.svg")}
                                  className="bottom-pdf-icon"
                                />
                              </PDFDownloadLink>
                            ) : null}
                          </div>
                          <ThemeProvider theme={outerTheme}>
                            <div className="bottom-icon">
                              <Checkbox
                                style={{ padding: 0 }}
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                                checkedIcon={<BookmarkIcon fontSize="small" />}
                                icon={
                                  <BookmarkBorderIcon
                                    fontSize="small"
                                    style={{ color: "#FFC65B" }}
                                  />
                                }
                                onClick={() => this.handleSavePlayer(player)}
                                checked={true}
                              />
                            </div>
                          </ThemeProvider>
                        </div>
                      );
                    })
                  ) : (
                    <p>There are no players</p>
                  )
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SavedPlayers;
