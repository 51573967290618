import React from "react";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { ThemeProvider } from "@material-ui/core/styles";
import Theme from "../theme/theme";

export default function MenuListComposition(props) {
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = React.useState(props.filter);
  const anchorRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (event === "filter") {
      props.handleFilter("filter");
    } else {
      props.handleFilter(event);
    }
    setFilter(event);
    setOpen(false);
  };

  const handleCloseClick = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <ThemeProvider theme={Theme}>
      {/* <div className={classes.root}> */}
      <div>
        <div>
          <div style={{ padding: 0 }}>
            <button
              className={
                filter === "filter" ? "kicker-btn" : "kicker-btn-filter"
              }
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              {filter}
            </button>
          </div>

          <Popper
            className="menu-itemM"
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper
                  style={{
                    background: "#262626",
                    borderRadius: 0,
                    width: "250%",
                  }}
                >
                  <ClickAwayListener onClickAway={handleCloseClick}>
                    <MenuList
                      style={{ padding: 0 }}
                      autoFocusItem={open}
                      onKeyDown={handleListKeyDown}
                    >
                      <Grid
                        container
                        direction="column"
                        spacing={4}
                        justify="space-between"
                        style={{ padding: 0, margin: 0 }}
                      >
                        <Grid
                          item
                          style={{
                            borderBottom: "0.1px solid #434343",
                            padding: "16px 16px 0",
                          }}
                        >
                          <p style={{ color: "white" }}>Select Position</p>
                        </Grid>
                        <Grid item style={{ padding: 0 }}>
                          <MenuItem
                            className="dropDown"
                            onClick={() => handleClose("Full Back")}
                          >
                            Full Back
                          </MenuItem>
                          <MenuItem
                            className="dropDown"
                            onClick={() => handleClose("Wing")}
                          >
                            Wing
                          </MenuItem>
                          <MenuItem
                            className="dropDown"
                            onClick={() => handleClose("Centre")}
                          >
                            Centre
                          </MenuItem>
                          <MenuItem
                            className="dropDown"
                            onClick={() => handleClose("Loose Head Prop")}
                          >
                            Loose Head Prop
                          </MenuItem>
                          <MenuItem
                            className="dropDown"
                            onClick={() => handleClose("Tight Head Prop")}
                          >
                            Tight Head Prop
                          </MenuItem>
                          <MenuItem
                            className="dropDown"
                            onClick={() => handleClose("Fly Half")}
                          >
                            Fly Half
                          </MenuItem>
                          <MenuItem
                            className="dropDown"
                            onClick={() => handleClose("Scrum Half")}
                          >
                            Scrum Half
                          </MenuItem>
                          <MenuItem
                            className="dropDown"
                            onClick={() => handleClose("Hooker")}
                          >
                            Hooker
                          </MenuItem>
                          <MenuItem
                            className="dropDown"
                            onClick={() => handleClose("Back Row")}
                          >
                            Back Row
                          </MenuItem>
                          <MenuItem
                            className="dropDown"
                            onClick={() => handleClose("Lock")}
                          >
                            Lock
                          </MenuItem>
                          <MenuItem
                            className="dropDown"
                            onClick={() => handleClose("No. 8")}
                          >
                            No. 8
                          </MenuItem>
                        </Grid>
                        <Grid item style={{ borderTop: "0.1px solid #434343" }}>
                          <Grid
                            container
                            sapcing={4}
                            justify="space-between"
                            direction="row"
                          >
                            <Grid item>
                              <p
                                className="user-btn"
                                style={{ fontSize: "14px", margin: 0 }}
                                onClick={() => {
                                  handleClose("filter");
                                }}
                              >
                                Clear
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    </ThemeProvider>
  );
}
