import React from "react";
import { withRouter } from "react-router";
import { Tab, Tabs } from "react-bootstrap";
import Index from "./index";

class LinkHeader extends React.Component {
  constructor(props) {
    super();
    this.state = {
      activeTab: props.activeTab || 1,
    };
  }

  render() {
    return (
      <div className="tab-container">
        <Tabs
          className="tab"
          defaultActiveKey={1}
          unmountOnExit={true}
          activeKey={this.state.activeTab}
          mountOnEnter={true}
        >
          <Tab eventKey={1} title="Send a link">
            <Index />
          </Tab>
        </Tabs>
      </div>
    );
  }
}
export default withRouter(LinkHeader);
