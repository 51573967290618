import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Modal from "react-modal";
import { ThemeProvider } from "@material-ui/core/styles";
import DotLoader from "react-spinners/DotLoader";
import UploadBGImage from "./uploadBGImage";
import BarLoader from "react-spinners/BarLoader";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import UploadImage from "./UploadImage";
import UploadVideo from "./UploadVideo";
import CountryOptions from "./countryOptions";
import theme from "../theme/checkTheme";
import {
  updatePlayer,
  removePlayer,
  hidePlayer,
  uploadVideoToDB,
  uploadBGImageToDb,
  generateId,
} from "../../../firebase/admin/players";
import { db } from "../../../util/firebase";
import { NavLink, Redirect } from "react-router-dom";

class EditPlayerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      bio: "",
      weight: "",
      height: "",
      caps: "",
      teamId: "",
      currentTeamAppearance: "",
      country: "",
      honors: "",
      ooc: "",
      age: "",
      status: "",
      imageAsFile: null,
      videoAsFile: null,
      hidden: false,
      BGImage: null,
      stats: [],
      appearances: [],
      teams: [],
      positions: [],
      positionDict: [],
      previousTeamDict: [],
      redirect: false,
      loading: true,
      videoLoading: false,
      posAlreadyAssigned: "",
      showModal: false,
    };
    this.handleImageAsFile = this.handleImageAsFile.bind(this);
    this.handleVideoAsFile = this.handleVideoAsFile.bind(this);
    Modal.setAppElement("#root");
  }

  getYears = () => {
    const currentYear = parseInt(
      new Date().getFullYear().toString().substr(-2)
    );
    return [
      `${currentYear - 1}/${currentYear}`,
      `${currentYear - 2}/${currentYear - 1}`,
      `${currentYear - 3}/${currentYear - 2}`,
    ];
  };

  onOpenModal = () => {
    this.setState({ showModal: true });
  };

  onCloseModal = () => {
    this.setState({ showModal: false, redirect: true });
  };

  async componentDidMount() {
    let positions = [];
    let teams = [];
    let previousTeamDict = [];
    let positionDict = [];

    const positionsSnapshot = await db.collection("positions").get();
    positionsSnapshot.docs.forEach((doc) => {
      positions.push(doc.data().name);
    });

    const teamsSnapshot = await db.collection("teams").get();
    teamsSnapshot.docs.forEach((doc) => {
      if (!doc.data().deleted) {
        teams.push({ id: doc.id, ...doc.data() });
      }
    });

    // getting player details
    const player = await db
      .collection("players")
      .doc(this.props.location.state.playerId)
      .get();
    const playerData = player.data();

    // setting previousTeams and positions in the state
    playerData.position.forEach((pos) => {
      positionDict.push({
        id: generateId(),
        name: pos,
      });
    });
    playerData.previousTeams.forEach((t) => {
      previousTeamDict.push({
        id: generateId(),
        teamId: t.teamId,
        appearance: t.appearance,
      });
    });

    this.setState({
      loading: false,
      positions: positions,
      stats: !playerData.statistics?.length
        ? [{ title: "", averageValue: 0, playerValue: 0 }]
        : playerData.statistics,
      appearances: !playerData.appearances?.length
        ? [{ year: "", value1: "", value2: "", value3: "" }]
        : playerData.appearances,
      teams: teams,
      id: player.id,
      name: playerData.name,
      bio: playerData.bio,
      weight: playerData.weight,
      height: playerData.height,
      caps: playerData.caps,
      teamId: playerData.teamId,
      currentTeamAppearance: playerData.currentTeamAppearance,
      country: playerData.country,
      honors: playerData.honors,
      ooc: playerData.ooc,
      age: playerData.age,
      status: playerData.status,
      hidden: playerData.hidden,
      BGImage: playerData.BGImageUrl,
      imageAsFile: playerData.profileImage,
      videoAsFile: playerData.videoUrl,
      positionDict: positionDict,
      previousTeamDict: previousTeamDict,
    });
  }

  handleImageAsFile = (e) => {
    e.preventDefault();
    this.setState({ imageAsFile: e.target.files[0] });
  };

  handleBGImageAsFile = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    uploadBGImageToDb(e.target.files[0], (BGImageUrl) => {
      this.setState({ BGImage: BGImageUrl, loading: false });
    });
  };

  handleVideoAsFile = (e) => {
    e.preventDefault();
    this.setState({ videoAsFile: e.target.files[0] });
    this.setState({ videoLoading: true });
    uploadVideoToDB(e.target.files[0], (videoUrl) => {
      this.setState({ videoAsFile: videoUrl, videoLoading: false });
    });
  };

  handleStatusChange = (e) => {
    if (e.target.value === "active") {
      this.setState({ status: true });
    } else if (e.target.value === "inactive") {
      this.setState({ status: false });
    } else this.setState({ status: null });
  };

  handleRemovePlayer = () => {
    if (window.confirm("Do you want to delete?")) {
      this.setState({ loading: true });
      removePlayer(this.state.id, () => {
        this.setState({ redirect: true });
      });
    }
  };

  handleHidePlayer = () => {
    this.setState({ loading: true });
    hidePlayer(this.state.id, this.state.hidden, () => {
      this.setState({ loading: false, hidden: !this.state.hidden });
    });
  };

  addAnotherPosition = () => {
    let positionDict = [...this.state.positionDict];
    positionDict.push({
      id: generateId(),
      name: "",
    });
    this.setState({ positionDict: positionDict });
  };

  handlePositions = (e, id) => {
    let exist = false;
    let positionDict = [...this.state.positionDict];
    let posAlreadyAssigned = "";

    positionDict.forEach((position) => {
      if (position.name === e.target.value) {
        exist = true;
      }
    });

    if (!exist) {
      const index = positionDict.findIndex((pos) => pos.id === id);
      positionDict[index].name = e.target.value;
    } else {
      posAlreadyAssigned =
        "This position is already been assigned to the player";
    }
    this.setState({
      positionDict: positionDict,
      posAlreadyAssigned: posAlreadyAssigned,
    });
  };

  handleRemovePosition = (e, id) => {
    this.setState({
      positionDict: this.state.positionDict.filter((pos) => pos.id !== id),
    });
  };

  addAnotherTeam = () => {
    let previousTeamDict = [...this.state.previousTeamDict];
    previousTeamDict.push({
      id: generateId(),
      teamId: "",
      appearance: 0,
    });
    this.setState({
      previousTeamDict: previousTeamDict,
    });
  };

  handlePreviousTeams = (e, id) => {
    let exist = false;
    let currentTeam = false;
    let previousTeamDict = [...this.state.previousTeamDict];

    previousTeamDict.forEach((pTeam) => {
      if (pTeam.teamId === e.target.value) {
        exist = true;
      }
      if (this.state.teamId === e.target.value) {
        currentTeam = true;
      }
    });

    if (!exist && !currentTeam) {
      const index = previousTeamDict.findIndex((pTeam) => pTeam.id === id);
      previousTeamDict[index].teamId = e.target.value;
    } else if (currentTeam) {
      //alert("This is the current team of the player")
    } else {
      //alert("The team already been added to the player history")
    }

    this.setState({
      previousTeamDict: previousTeamDict,
    });
  };

  handleTeamAppearance = (e, id) => {
    let previousTeamDict = [...this.state.previousTeamDict];
    const index = previousTeamDict.findIndex((pTeam) => pTeam.id === id);
    previousTeamDict[index].appearance = e.target.value;

    this.setState({
      previousTeamDict: previousTeamDict,
    });
  };

  handleRemoveTeam = (e, id) => {
    this.setState({
      previousTeamDict: this.state.previousTeamDict.filter(
        (pTeam) => pTeam.id !== id
      ),
    });
  };

  handleAppearanceChange = (index, updatedAppearance) => {
    const appearancesCopy = [...this.state.appearances];
    appearancesCopy[index] = updatedAppearance;
    this.setState({ appearances: appearancesCopy });
  };

  addAnotherAppearance = () => {
    let previousAppearances = [...this.state.appearances];
    previousAppearances.push({
      year: "",
      value1: "",
      value2: "",
      value3: "",
    });
    this.setState({ appearances: previousAppearances });
  };

  removeAppearance = (index) => {
    const appearancesCopy = [...this.state.appearances];
    appearancesCopy.splice(index, 1);
    this.setState({ appearances: appearancesCopy });
  };

  addAnotherStat = () => {
    let previousStats = [...this.state.stats];
    previousStats.push({
      title: "",
      averageValue: 0,
      playerValue: 0,
    });
    this.setState({ stats: previousStats });
  };

  handleStatChange = (index, updatedStat) => {
    const statsCopy = [...this.state.stats];
    statsCopy[index] = updatedStat;
    this.setState({ stats: statsCopy });
  };

  removeStat = (index) => {
    const statsCopy = [...this.state.stats];
    statsCopy.splice(index, 1);
    this.setState({ stats: statsCopy });
  };

  updatePlayer = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    let previousTeams = this.state.previousTeamDict.filter(
      (pTeam) => pTeam.teamId !== ""
    );
    let position = this.state.positionDict.filter((pos) => pos.name !== "");

    let player = {
      id: this.state.id,
      name: this.state.name,
      position: position.map((pos) => pos.name),
      bio: this.state.bio,
      weight: this.state.weight,
      height: this.state.height,
      caps: this.state.caps,
      teamId: this.state.teamId,
      currentTeamAppearance: this.state.currentTeamAppearance,
      country: this.state.country,
      honors: this.state.honors,
      ooc: this.state.ooc,
      age: this.state.age,
      status: this.state.status,
      profileImage: this.state.imageAsFile,
      statistics: this.state.stats.filter(
        (stat) => stat.title && stat.averageValue && stat.playerValue
      ),
      appearances: this.state.appearances.filter((obj) => !!obj.year),
      previousTeams: previousTeams.map((pTeam) => ({
        teamId: pTeam.teamId,
        appearance: pTeam.appearance,
      })),
      BGImageUrl: this.state.BGImage,
      videoUrl: this.state.videoAsFile,
    };

    updatePlayer(player, () => {
      this.setState({
        loading: false,
        showModal: true,
        position: position,
        previousTeams: previousTeams,
      });
    });
  };

  render() {
    const {
      redirect,
      loading,
      showModal,
      videoLoading,
      name,
      positionDict,
      positions,
      bio,
      imageAsFile,
      videoAsFile,
      BGImage,
      stats,
      appearances,
      previousTeamDict,
      hidden,
      teams,
      country,
      caps,
      age,
      weight,
      height,
      status,
      honors,
      ooc,
      teamId,
      currentTeamAppearance,
    } = this.state;

    return redirect ? (
      <Redirect to="/admin/cms/3" />
    ) : (
      <div className="tab-container" style={{ padding: "3% 6%" }}>
        <ThemeProvider theme={theme}>
          <div className={loading ? "parentDisable" : ""}>
            <div className="overlay-box">
              <DotLoader size={150} color="#FFC65B" loading={loading} />
            </div>
          </div>
          <div className={showModal ? "modal-overlay" : ""}>
            <Modal
              className="modal-link"
              overlayClassName="mdl-overlay"
              isOpen={showModal}
              onRequestClose={this.onCloseModal}
            >
              <div className="modal-body">
                <p className="modal-text">
                  Success! the player has been updated
                </p>
                <Button
                  className="modal-btn"
                  type="submit"
                  onClick={() => {
                    this.setState({ showModal: false, redirect: true });
                  }}
                >
                  DONE
                </Button>
              </div>
            </Modal>
          </div>
          <div className={videoLoading ? "parentDisable" : ""}>
            <div className="overlay-box">
              <p
                className="text"
                style={videoLoading ? {} : { display: "none" }}
              >
                Uploading Video..
              </p>
              <BarLoader size={150} color="#FFC65B" loading={videoLoading} />
            </div>
          </div>
          <div style={{ padding: "2% 0" }}>
            <NavLink
              to="/admin/cms/3"
              className="nav-link mr-3"
              style={{ color: "#E22727", textDecoration: "none" }}
            >
              <Grid container alignItems="center">
                <ArrowBackIosIcon style={{ color: "#E22727" }} />
                <p
                  className="text"
                  style={{ color: "#E22727", fontSize: "14px" }}
                >
                  Cancel and go back
                </p>
              </Grid>
            </NavLink>
          </div>
          <form autoComplete="off" style={{ padding: "10px 0" }}>
            <Grid container direction="column" spacing={3}>
              <Grid item style={{ padding: "0 12px" }}>
                <p
                  className="text pointsHeading"
                  style={{ color: "#FFC65B", margin: "40px 0 0 0" }}
                >
                  Main Info
                </p>
              </Grid>
              <Grid item sm={6} xs={12} md={4}>
                <Paper className="full-input efull-input-player">
                  <label className="floating-label" htmlFor="name">
                    PLAYER NAME
                  </label>
                  <input
                    className="input"
                    required
                    onChange={(e) => {
                      this.setState({ name: e.target.value });
                    }}
                    type="text"
                    placeholder="John Doe"
                    value={name}
                  ></input>
                </Paper>
              </Grid>
              <Grid item style={{ padding: "0px 12px" }}>
                <p
                  className="text pointsHeading "
                  style={{ color: "#FFC65B", margin: 0 }}
                >
                  Positions
                </p>
              </Grid>
              {positionDict.map((position, index) => {
                return (
                  <Grid key={index} item xs={12} md={8}>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={12} md={6}>
                        <Paper className="full-input efull-input-position">
                          <label className="floating-label">POSITION</label>
                          <select
                            required
                            value={position.name}
                            className="custom-select"
                            onChange={(e) => {
                              this.handlePositions(e, position.id);
                            }}
                          >
                            <option value={null}>Select</option>
                            {positions.map((position, i) => (
                              <option key={i} value={position}>
                                {position}
                              </option>
                            ))}
                          </select>
                        </Paper>
                      </Grid>
                      {index + 1 === positionDict.length ? (
                        <Grid item sm={6} xs={12} md={6}>
                          <Grid
                            container
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Grid item>
                              <div
                                className="crossPre"
                                color="primary"
                                aria-label="add"
                                size="small"
                                onClick={this.addAnotherPosition}
                              >
                                <img
                                  alt=""
                                  src={require("../../../assets/images/add_circle.svg")}
                                  className="add-plus"
                                />
                              </div>
                            </Grid>
                            <Grid item>
                              <p
                                className="text"
                                style={{ color: "#FFFFFF", fontSize: "14px" }}
                              >
                                Add another Position
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item xs={10} md={6}>
                          <div
                            className="crossPre"
                            color="primary"
                            aria-label="add"
                            size="small"
                            onClick={(e) =>
                              this.handleRemovePosition(
                                e.target.value,
                                position.id
                              )
                            }
                          >
                            <img
                              alt=""
                              src={require("../../../assets/images/add_circle.svg")}
                              className="remove-plus"
                            />
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
              <Grid item xs={12} md={8}>
                <Paper
                  className="efull-input-bio"
                  style={{ backgroundColor: "rgb(255, 255, 255, 0.26)" }}
                >
                  <label className="floating-label">BIO</label>
                  <textarea
                    className="input"
                    required
                    value={bio}
                    onChange={(e) => {
                      this.setState({ bio: e.target.value });
                    }}
                    type="text"
                    placeholder="Left back"
                  ></textarea>
                </Paper>
              </Grid>
              <Grid item className="editHeadingSpace">
                <p
                  className="text pointsHeading "
                  style={{ color: "#FFC65B", margin: "30px 0 0" }}
                >
                  Content
                </p>
              </Grid>
              <Grid item>
                <Grid container spacing={4}>
                  <Grid item sm={3} xs={6} md={2} className="addPlayer">
                    <UploadImage
                      type="profile"
                      imageFile={imageAsFile}
                      handleImageAsFile={this.handleImageAsFile}
                    />
                  </Grid>
                  <Grid item xs={6} md={3} className="videoPlayer">
                    <UploadVideo
                      videoFile={videoAsFile}
                      handleVideoAsFile={this.handleVideoAsFile}
                    />
                  </Grid>
                  <Grid item xs={6} md={3} className="addPlayer">
                    <UploadBGImage
                      type="BGImage"
                      imageFile={BGImage}
                      handleImageAsFile={this.handleBGImageAsFile}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="editHeadingSpace">
                <p
                  className="text pointsHeading editSpacing"
                  style={{ color: "#FFC65B", marginBottom: 0 }}
                >
                  Stats
                </p>
              </Grid>
              <Grid item style={{ paddingTop: 0 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4} md={2}>
                    <Paper
                      className="full-input-select borderRadius-left"
                      style={{ backgroundColor: "rgb(255, 255, 255, 0.26)" }}
                    >
                      <label className="floating-label stats-label">
                        WEIGHT
                      </label>
                      <input
                        className="inputS"
                        required
                        min="0"
                        placeholder="60.34"
                        value={weight}
                        type="number"
                        step=".01"
                        onChange={(e) => {
                          this.setState({ weight: e.target.value });
                        }}
                      ></input>
                      <span className="unit-span">kg</span>
                    </Paper>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Paper
                      className="full-input-select"
                      style={{ backgroundColor: "rgb(255, 255, 255, 0.26)" }}
                    >
                      <label className="floating-label stats-label">
                        HEIGHT
                      </label>
                      <input
                        className="inputS"
                        value={height}
                        placeholder="580"
                        required
                        type="number"
                        min="0"
                        step=".01"
                        onChange={(e) => {
                          this.setState({ height: e.target.value });
                        }}
                      ></input>
                      <span className="unit-span">cm</span>
                    </Paper>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Paper
                      className="full-input-select borderRadius-rightM "
                      style={{ backgroundColor: "rgb(255, 255, 255, 0.26)" }}
                    >
                      <label className="floating-label stats-label">
                        COUNTRY
                      </label>
                      <select
                        className="custom-select"
                        required
                        value={country}
                        onChange={(e) => {
                          this.setState({ country: e.target.value });
                        }}
                      >
                        <CountryOptions />
                      </select>
                    </Paper>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Paper
                      className="full-input-select borderRadius-leftM"
                      style={{ backgroundColor: "rgb(255, 255, 255, 0.26)" }}
                    >
                      <label className="floating-label stats-label">CAPS</label>
                      <input
                        className="input"
                        required
                        value={caps}
                        placeholder="7"
                        type="number"
                        min="0"
                        onChange={(e) => {
                          this.setState({ caps: e.target.value });
                        }}
                      ></input>
                    </Paper>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Paper
                      className="full-input-select"
                      style={{ backgroundColor: "rgb(255, 255, 255, 0.26)" }}
                    >
                      <label className="floating-label stats-label">DOB</label>
                      <input
                        type="date"
                        className="input"
                        value={age}
                        onChange={(e) => {
                          this.setState({ age: e.target.value, changed: true });
                        }}
                      ></input>
                    </Paper>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Paper
                      className="full-input-select borderRadius-right"
                      style={{ backgroundColor: "rgb(255, 255, 255, 0.26)" }}
                    >
                      <label className="floating-label stats-label">
                        STATUS
                      </label>
                      <input
                        className="input"
                        placeholder="Active"
                        value={status}
                        type="text"
                        onChange={(e) => {
                          this.setState({ status: e.target.value });
                        }}
                      ></input>
                    </Paper>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Paper
                      className="full-input-select borderRadius-left"
                      style={{ backgroundColor: "rgb(255, 255, 255, 0.26)" }}
                    >
                      <label className="floating-label stats-label">
                        HONOURS
                      </label>
                      <input
                        className="input"
                        placeholder="England U20’s"
                        required
                        value={honors}
                        type="text"
                        onChange={(e) => {
                          this.setState({ honors: e.target.value });
                        }}
                      ></input>
                    </Paper>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Paper
                      className="full-input-select borderRadius-right"
                      style={{ backgroundColor: "rgb(255, 255, 255, 0.26)" }}
                    >
                      <label className="floating-label stats-label">OOC</label>
                      <input
                        type="date"
                        className="input"
                        required
                        value={ooc}
                        onChange={(e) => {
                          this.setState({ ooc: e.target.value });
                        }}
                      ></input>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <p
                  className="text pointsHeading"
                  style={{ color: "#FFC65B", marginBottom: 0 }}
                >
                  Appearances
                </p>
              </Grid>
              {appearances.map((appearance, i) => (
                <Grid key={i} item>
                  <Grid container alignItems="center">
                    <Grid item xs={12}>
                      <label className="floating-label stats-label">Date</label>
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <Box
                        width={80}
                        borderRadius={5}
                        className="no-label-input full-input-appearance"
                      >
                        <select
                          value={appearance.year}
                          className="custom-select full-select"
                          onChange={(e) => {
                            this.handleAppearanceChange(i, {
                              ...appearance,
                              year: e.target.value,
                            });
                          }}
                        >
                          <option value="">Select</option>
                          {this.getYears().map((year, i) => (
                            <option key={i} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </Box>
                    </Grid>
                    {appearances.length === i + 1 && appearances.length < 3 ? (
                      <Grid item xs={8} md={10}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          onClick={this.addAnotherAppearance}
                        >
                          <img
                            alt=""
                            src={require("../../../assets/images/add_circle.svg")}
                            className="add-plus"
                          />
                          <Box fontSize="14px" color="#fff" marginLeft="10px">
                            Add another Date
                          </Box>
                        </Box>
                      </Grid>
                    ) : (
                      <Grid item xs={8} md={10}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          onClick={(e) => this.removeAppearance(i)}
                        >
                          <img
                            alt=""
                            src={require("../../../assets/images/add_circle.svg")}
                            className="remove-plus"
                          />
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Box
                        width={170}
                        borderRadius={5}
                        className="no-label-input full-input-appearance mt-0"
                      >
                        <input
                          value={appearance.value1}
                          className="input"
                          type="text"
                          placeholder="value 1"
                          onChange={(e) =>
                            this.handleAppearanceChange(i, {
                              ...appearance,
                              value1: e.target.value,
                            })
                          }
                        ></input>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        width={170}
                        borderRadius={5}
                        className="no-label-input full-input-appearance mt-0"
                      >
                        <input
                          className="input"
                          type="text"
                          placeholder="value 2"
                          value={appearance.value2}
                          onChange={(e) =>
                            this.handleAppearanceChange(i, {
                              ...appearance,
                              value2: e.target.value,
                            })
                          }
                        ></input>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        width={170}
                        borderRadius={5}
                        className="no-label-input full-input-appearance mt-0"
                      >
                        <input
                          className="input"
                          type="text"
                          placeholder="value 3"
                          value={appearance.value3}
                          onChange={(e) =>
                            this.handleAppearanceChange(i, {
                              ...appearance,
                              value3: e.target.value,
                            })
                          }
                        ></input>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid item>
                <p
                  className="text pointsHeading"
                  style={{ color: "#FFC65B", marginBottom: 0 }}
                >
                  Graph
                </p>
              </Grid>
              {stats.map((stat, i) => (
                <Grid key={i} container alignItems="center">
                  <Grid item sm={3} xs={6} md={2}>
                    <label className="floating-label stats-label">
                      Statistic title
                    </label>
                    <Paper className="full-input-appearance no-label-input">
                      <input
                        value={stat.title}
                        className="input"
                        placeholder="title"
                        type="text"
                        min="0"
                        onChange={(e) =>
                          this.handleStatChange(i, {
                            ...stat,
                            title: e.target.value,
                          })
                        }
                      ></input>
                    </Paper>
                  </Grid>
                  <Grid item sm={3} xs={6} md={2}>
                    <label className="floating-label stats-label">
                      Average value
                    </label>
                    <Paper className="full-input-appearance no-label-input">
                      <input
                        value={stat.averageValue}
                        className="input"
                        placeholder="0"
                        type="number"
                        min="0"
                        onChange={(e) =>
                          this.handleStatChange(i, {
                            ...stat,
                            averageValue: e.target.value,
                          })
                        }
                      ></input>
                    </Paper>
                  </Grid>
                  <Grid item sm={3} xs={6} md={2}>
                    <label className="floating-label stats-label">
                      Players value
                    </label>
                    <Paper className="full-input-appearance no-label-input">
                      <input
                        className="input"
                        placeholder="0"
                        type="number"
                        min="0"
                        value={stat.playerValue}
                        onChange={(e) =>
                          this.handleStatChange(i, {
                            ...stat,
                            playerValue: e.target.value,
                          })
                        }
                      ></input>
                    </Paper>
                  </Grid>
                  {stats.length === i + 1 && stats.length < 7 ? (
                    <Grid item sm={3} xs={6} md={6}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        onClick={this.addAnotherStat}
                      >
                        <img
                          alt=""
                          src={require("../../../assets/images/add_circle.svg")}
                          className="add-plus"
                        />
                        <Box
                          display={{ xs: "none", sm: "block" }}
                          fontSize="14px"
                          color="#fff"
                          marginLeft="10px"
                        >
                          Add another Statistic
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item sm={3} xs={6} md={6}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        onClick={(e) => this.removeStat(i)}
                      >
                        <img
                          alt=""
                          src={require("../../../assets/images/add_circle.svg")}
                          className="remove-plus"
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              ))}
              <Grid item style={{ padding: "0px 12px" }}>
                <p
                  className="text pointsHeading editSpacing"
                  style={{ color: "#FFC65B", marginBottom: 0 }}
                >
                  Teams
                </p>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item sm={6} xs={12} md={4}>
                    <Paper
                      className="full-input efull-input-position"
                      style={{ backgroundColor: "rgb(255, 255, 255, 0.26)" }}
                    >
                      <label
                        className="floating-label pointsHeading"
                        htmlFor="name"
                      >
                        TEAM
                      </label>
                      <select
                        required
                        value={teamId}
                        className="custom-select"
                        onChange={(e) => {
                          this.setState({ teamId: e.target.value });
                        }}
                      >
                        <option value={null}>Select</option>
                        {teams.map((team, i) => (
                          <option key={i} value={team.id}>
                            {team.name}
                          </option>
                        ))}
                      </select>
                    </Paper>
                  </Grid>
                  <Grid item sm={6} xs={12} md={2}>
                    <Paper
                      className="full-input full-input-appearance"
                      style={{ marginTop: 0, marginBottom: 0 }}
                    >
                      <label className="floating-label stats-label">
                        APPEARANCES
                      </label>
                      <input
                        className="input"
                        style={{ width: "80%" }}
                        value={currentTeamAppearance}
                        placeholder="7"
                        type="number"
                        min="0"
                        onChange={(e) => {
                          this.setState({
                            currentTeamAppearance: e.target.value,
                            changed: true,
                          });
                        }}
                      ></input>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>

              {previousTeamDict.map((pTeam, index) => {
                return (
                  <Grid key={index} item>
                    <Grid container alignItems="center">
                      <Grid item sm={6} xs={12} md={4}>
                        <Paper className="full-input efull-input-position">
                          <label className="floating-label">
                            PREVIOUS TEAM
                          </label>
                          <select
                            value={pTeam.teamId}
                            required
                            className="custom-select"
                            onChange={(e) =>
                              this.handlePreviousTeams(e, pTeam.id)
                            }
                          >
                            <option value={null}>Select</option>
                            {teams.map((team, i) => (
                              <option key={i} value={team.id}>
                                {team.name}
                              </option>
                            ))}
                          </select>
                        </Paper>
                      </Grid>
                      <Grid item sm={6} xs={12} md={2}>
                        <Paper
                          className="full-input full-input-appearance"
                          style={{ marginTop: 0, marginBottom: 0 }}
                        >
                          <label className="floating-label stats-label">
                            APPEARANCES
                          </label>
                          <input
                            className="input"
                            style={{ width: "80%" }}
                            value={pTeam.appearance}
                            placeholder="7"
                            type="number"
                            min="0"
                            onChange={(e) =>
                              this.handleTeamAppearance(e, pTeam.id)
                            }
                          ></input>
                        </Paper>
                      </Grid>
                      {previousTeamDict.length === index + 1 ? (
                        <Grid
                          item
                          sm={6}
                          xs={12}
                          md={4}
                          style={{ paddingLeft: 30 }}
                        >
                          <Grid
                            container
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Grid item>
                              <div
                                color="primary"
                                aria-label="add"
                                size="small"
                                onClick={this.addAnotherTeam}
                              >
                                <img
                                  alt=""
                                  src={require("../../../assets/images/add_circle.svg")}
                                  className="add-plus"
                                />
                              </div>
                            </Grid>
                            <Grid item>
                              <p
                                className="text"
                                style={{ color: "#FFFFFF", fontSize: "14px" }}
                              >
                                Add another Team
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item className="crossPre" xs={10} md={6}>
                          <div
                            color="primary"
                            aria-label="add"
                            size="small"
                            onClick={(e) => this.handleRemoveTeam(e, pTeam.id)}
                          >
                            <img
                              alt=""
                              src={require("../../../assets/images/add_circle.svg")}
                              className="remove-plus"
                            />
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
              <Grid item>
                <Grid
                  container
                  direction="row"
                  spacing={0}
                  style={{ marginTop: "10px" }}
                >
                  <Grid item xs={12} sm={4} md={7}>
                    <Button
                      className="edit-buttons update-btn"
                      onClick={this.updatePlayer}
                    >
                      Update Player
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} className="alignEdit-Btn">
                    <Button
                      className=" edit-buttons remove-btn"
                      onClick={() => this.handleRemovePlayer()}
                    >
                      Remove Player
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} className="alignRemove-Btn">
                    <Button
                      className={
                        hidden
                          ? "edit-buttons show-btn"
                          : "edit-buttons hide-btn"
                      }
                      onClick={this.handleHidePlayer}
                    >
                      {hidden ? "Show Player" : "Hide Player"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </ThemeProvider>
      </div>
    );
  }
}

export default EditPlayerDetails;
