import React from "react";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Theme from "../theme/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function MenuListComposition(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [sort, setFilter] = React.useState("shuffle");
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    props.handleSort(event);
    setFilter(event);
    setOpen(false);
  };

  const handleCloseClick = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <ThemeProvider theme={Theme}>
      <div className={classes.root}>
        <div>
          <Grid container>
            <div className="col-md-12 col-xs-12" style={{ padding: 0 }}>
              <button
                className={
                  sort === "shuffle" ? "kicker-btn" : "kicker-btn-filter"
                }
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              >
                Sort
              </button>
            </div>
          </Grid>
          <Popper
            className="sort-menuM"
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper
                  style={{
                    background: "#262626",
                    borderRadius: 0,
                    width: "250%",
                  }}
                >
                  <ClickAwayListener onClickAway={handleCloseClick}>
                    <MenuList
                      style={{ padding: 0 }}
                      autoFocusItem={open}
                      onKeyDown={handleListKeyDown}
                    >
                      <Grid
                        container
                        direction="column"
                        spacing={4}
                        justify="space-between"
                        style={{ padding: 0, margin: 0 }}
                      >
                        <Grid item style={{ padding: 0 }}>
                          <MenuItem
                            className="dropDown"
                            onClick={() => handleClose("Full Back")}
                          >
                            OOC date
                          </MenuItem>
                        </Grid>
                        <Grid item style={{ borderTop: "0.1px solid #434343" }}>
                          <Grid
                            container
                            sapcing={4}
                            justify="space-between"
                            direction="row"
                          >
                            <Grid item>
                              <span
                                className="user-btn"
                                style={{ fontSize: "14px" }}
                                onClick={() => {
                                  handleClose("shuffle");
                                }}
                              >
                                Clear
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    </ThemeProvider>
  );
}
