import { storage } from "../../util/firebase";
import { db } from "../../util/firebase";

export const uploadBGImageToDb = (imageFile, callBack) => {
  const extension = imageFile.name.split('.').pop();
  const newFileName = Date.now() + '.' + extension;

  const addImage = storage
    .ref("/playerBackgroundImage/" + newFileName)
    .put(imageFile);
  
  addImage.on(
    "state_changed",
    (snapShot) => {
      console.log(snapShot);
    },
    (err) => {
      console.log(err);
    },
    () => {
      storage
        .ref("playerBackgroundImage")
        .child(newFileName)
        .getDownloadURL()
        .then((fireBaseUrl) => {
          callBack(fireBaseUrl);
        });
    }
  );
};

export const uploadVideoToDB = (videoFile, callBack) => {
  const extension = videoFile.name.split('.').pop();
  const newFileName = Date.now() + '.' + extension;

  const addVideo = storage
    .ref("/playerprofile-video/" + newFileName)
    .put(videoFile);
  
  addVideo.on(
    "state_changed",
    (snapShot) => {
      console.log(snapShot);
    },
    (err) => {
      console.log(err);
    },
    () => {
      storage
        .ref("playerprofile-video")
        .child(newFileName)
        .getDownloadURL()
        .then((fireBaseUrl) => {
          callBack(fireBaseUrl);
        });
    }
  );
};

export const addPlayer = (player, playerCallback) => {
  if (player.profileImage == null) {
    db.collection("players")
      .add(player)
      .then(function (docRef) {
        console.log("Player data uploaded", docRef);
        playerCallback();
      });
  } else {
    const extension = player.profileImage.name.split('.').pop();
    const newFileName = Date.now() + '.' + extension;
    
    const addImage = storage
      .ref("/playerProfileImage/" + newFileName)
      .put(player.profileImage);
    
    addImage.on(
      "state_changed",
      (snapShot) => {
        console.log(snapShot);
      },
      (err) => {
        console.log(err);
      },
      () => {
        storage
          .ref("playerProfileImage")
          .child(newFileName)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            player.profileImage = fireBaseUrl;
            db.collection("players")
              .add(player)
              .then(function (docRef) {
                console.log("Player data uploaded", docRef);
                playerCallback();
              });
          });
      }
    );
  }
};

export const updatePlayer = async (player, playerCallback) => {
  const ref = db.collection("players").doc(player.id);
  await ref.get().then((data) => {
    let existingData = data.data();
    if (player.profileImage === existingData.profileImage) {
      const playerRef = db.collection("players").doc(player.id);
      playerRef.update(player).then(function (docRef) {
        console.log("Player data updated", docRef);
        playerCallback();
      });
    } else if (player.profileImage !== existingData.profileImage) {
      const addImage = storage
        .ref("/playerProfileImage/" + player.profileImage.name)
        .put(player.profileImage);
      addImage.on(
        "state_changed",
        (snapShot) => {
          console.log(snapShot);
        },
        (err) => {
          console.log(err);
        },
        () => {
          storage
            .ref("playerProfileImage")
            .child(player.profileImage.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              player.profileImage = fireBaseUrl;
              const playerRef = db.collection("players").doc(player.id);
              playerRef.update(player).then(playerCallback());
              return;
            });
        }
      );
    }
  });
  console.log("DONE");
};

export const removePlayer = (player, callBack) => {
  const playerRef = db.collection("players").doc(player);
  playerRef.update({ deleted: true }).then(callBack());
};

export const hidePlayer = (player, status, callBack) => {
  const playerRef = db.collection("players").doc(player);
  playerRef.update({ hidden: !status }).then(callBack());
};

export const generateId = function () {
  return '_' + Math.random().toString(36).substr(2, 9);
};
