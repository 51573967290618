export const positions = [
  {
    id: 1,
    name: "Loose Head Prop",
  },
  {
    id: 2,
    name: "Hooker",
  },
  {
    id: 3,
    name: "Tight Head Prop",
  },
  {
    id: 4,
    name: "Lock",
  },
  {
    id: 5,
    name: "Lock",
  },
  {
    id: 6,
    name: "Back Row",
  },
  {
    id: 7,
    name: "Back Row",
  },
  {
    id: 8,
    name: "No. 8",
  },
  {
    id: 9,
    name: "Scrum Half",
  },
  {
    id: 10,
    name: "Fly Half",
  },
  {
    id: 11,
    name: "Wing",
  },
  {
    id: 12,
    name: "Centre",
  },
  {
    id: 13,
    name: "Centre",
  },
  {
    id: 14,
    name: "Wing",
  },
  {
    id: 15,
    name: "Full Back",
  },
];
