import React from "react";
import {withRouter} from 'react-router';
import {Tab, Tabs} from "react-bootstrap";
import KPIS from './KPIS';
import AutoAlert from './autoAlert';

class AnalyticsHead extends React.Component {
    constructor(props) {
      super();
      this.state = {
        activeTab: props.activeTab || 2
      };
      this.handleSelect = this.handleSelect.bind(this);
    }
    handleSelect(selectedTab) {
        this.setState({
          activeTab: selectedTab
        });
      }
    
    render() {
      return (
        <div className = 'tab-container' >
            <Tabs className="tab"
                defaultActiveKey = {1} 
                unmountOnExit = {true} 
                activeKey={this.state.activeTab} 
                mountOnEnter = {true}
                onSelect={this.handleSelect}>
            <Tab eventKey={1} title="KPIS"><KPIS/></Tab>
            <Tab eventKey={2} title="Auto Alert"><AutoAlert/></Tab>
            </Tabs>
        </div>
      );
    }
  }
  export default withRouter(AnalyticsHead);