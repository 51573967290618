import React from "react";
import { withRouter } from "react-router";
import { Tab, Tabs, Button } from "react-bootstrap";
import Modal from "react-modal";
import AddPlayer from "./AddPlayer";
import EditPlayer from "./EditPlayer";
import Teams from "./Teams";

class CMSHeader extends React.Component {
  constructor(props) {
    super();
    this.state = {
      activeTab: props.match.params.selectedTab,
      showModal: false,
      nextTab: 1,
      stateChanged: false,
    };
    this.handleSelect = this.handleSelect.bind(this);
    Modal.setAppElement("#root");
  }

  setStateChanged = (changed) => {
    this.setState({ stateChanged: changed });
  };

  handleSelect(key) {
    if (this.state.stateChanged) {
      this.setState({ showModal: true, nextTab: key, stateChanged: false });
    } else {
      this.setState({ activeTab: key, nextTab: key });
    }
  }

  handleOk = () => {
    this.setState({
      activeTab: this.state.nextTab,
      showModal: false,
      stateChanged: false,
    });
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <div className="tab-container">
        <div className={this.state.showModal ? "modal-overlay" : ""}>
          <Modal
            className="modal-link"
            overlayClassName="mdl-overlay"
            isOpen={this.state.showModal}
            onRequestClose={this.onCloseModal}
          >
            <div className="modal-body">
              <p className="modal-text">
                You will lose progress if you leave this page
              </p>
              <div className="modal-buttons">
                <Button
                  className="modal-btn-tabs okBtn"
                  onClick={() => this.handleOk()}
                >
                  OK
                </Button>
                <Button
                  className="modal-btn-tabs cancelBtn"
                  style={{ background: "#707070" }}
                  onClick={() => this.handleCancel()}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </Modal>
        </div>

        <Tabs
          className="tab"
          defaultActiveKey={1}
          unmountOnExit={true}
          activeKey={this.state.activeTab}
          mountOnEnter={true}
          onSelect={(key) => this.handleSelect(key)}
        >
          <Tab eventKey={1} title="Teams">
            <Teams setStateChanged={this.setStateChanged} />
          </Tab>
          <Tab eventKey={2} title="Add Player">
            <AddPlayer setStateChanged={this.setStateChanged} />
          </Tab>
          <Tab eventKey={3} title="Edit Player">
            <EditPlayer setStateChanged={this.setStateChanged} />
          </Tab>
        </Tabs>
      </div>
    );
  }
}
export default withRouter(CMSHeader);
